import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { 
  FaPlay, 
  FaStar, 
  FaFilter,
  FaFilm,
  FaTv,
  FaDragon
} from 'react-icons/fa';
import { 
  fetchFilteredContent, 
  fetchGenres 
} from '../services/api';


const getCategoryIcon = (mediaType) => {
  switch (mediaType) {
    case 'movie':
      return FaFilm;
    case 'tv':
      return FaTv;
    case 'anime':
      return FaDragon;
    default:
      return FaFilm;
  }
};

const FilterPage = () => {
  // State Management
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    genre: '',
    type: '',
    minRating: '',
    yearFrom: '',
    yearTo: '',
    sortBy: 'popularity'
  });
  const [genres, setGenres] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch Genres and Initial Content
  useEffect(() => {
    const initializeData = async () => {
      try {
        // Fetch genres
        const genreData = await fetchGenres();
        setGenres(genreData);

        // Fetch initial filtered content
        await fetchContent(true);
      } catch (error) {
        console.error('Initialization error:', error);
      }
    };

    initializeData();
  }, []);

  // Fetch Content Based on Filters
  const fetchContent = useCallback(async (isReset = false) => {
    try {
      setLoading(true);
      const currentPage = isReset ? 1 : page;
      
      const result = await fetchFilteredContent({
        ...filters,
        page: currentPage
      });
  
      if (!result || !result.results) {
        setContent([]);
        setHasMore(false);
        setLoading(false);
        return;
      }
  
      const processedContent = isReset 
        ? result.results 
        : [
            ...content, 
            ...result.results.filter(
              newItem => !content.some(existingItem => existingItem.id === newItem.id)
            )
          ];
  
      setContent(processedContent);
      setTotalPages(result.total_pages || 0);
      setHasMore(currentPage < (result.total_pages || 0));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching filtered content:', error);
      setContent([]);
      setHasMore(false);
      setLoading(false);
    }
  }, [filters, page]);  // Removed 'content' from dependency array

  // Trigger fetch when filters change
  useEffect(() => {
    fetchContent(true);
  }, [filters, fetchContent]);

  // Handle Filter Changes
  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => {
      // Validate and sanitize filters
      const sanitizedFilters = {
        genre: newFilters.genre || '',
        type: newFilters.type || '',
        minRating: newFilters.minRating || '',
        yearFrom: newFilters.yearFrom || '',
        yearTo: newFilters.yearTo || '',
        sortBy: newFilters.sortBy || 'popularity'
      };
  
      // Reset page when filters change
      setPage(1);
      
      return sanitizedFilters;
    });
  };

  // Load More Content
  const loadMoreContent = () => {
    if (hasMore && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  // Reset Filters
  const resetFilters = () => {
    setFilters({
      genre: '',
      type: '',
      minRating: '',
      yearFrom: '',
      yearTo: '',
      sortBy: 'popularity'
    });
  };

  // Render Filter Sidebar
  const renderFilterSidebar = () => {
    return (
      <div className="bg-zinc-900 p-6 rounded-xl shadow-2xl sticky top-24">
        <h2 className="text-2xl font-bold text-gold-500 mb-6 flex items-center">
          <FaFilter className="mr-3" /> Filters
        </h2>

        {/* Media Type Filter */}
        <div className="mb-4">
          <label className="block text-gold-400 mb-2">Media Type</label>
          <div className="flex space-x-2 flex-wrap">
            {[
              { key: '', label: 'All', icon: <FaFilm className="mr-2" /> },
              { key: 'movie', label: 'Movies', icon: <FaFilm className="mr-2" /> },
              { key: 'tv', label: 'TV Shows', icon: <FaTv className="mr-2" /> },
              { key: 'anime', label: 'Anime', icon: <FaDragon className="mr-2" /> }
            ].map(({ key, label, icon }) => (
              <button
                key={key}
                onClick={() => handleFilterChange({ type: key })}
                className={`flex items-center px-4 py-2 rounded-lg transition duration-300 
                            ${filters.type === key ? 'bg-gold-500 text-black' : 'bg-gray-800 text-gold-400'}`}
              >
                {icon}
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Genre Filter */}
        <div className="mb-4">
          <label className="block text-gold-400 mb-2">Genre</label>
          <select
            value={filters.genre}
            onChange={(e) => handleFilterChange({ genre: e.target.value })}
            className="bg-gray-800 text-gold-400 rounded-lg p-2 w-full"
          >
            <option value="">All Genres</option>
            {genres.map((genre) => (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            ))}
          </select>
        </div>

        {/* Rating Filter */}
        <div className="mb-4">
          <label className="block text-gold-400 mb-2">Minimum Rating</label>
          <input
            type="number"
            min="0"
            max="10"
            value={filters.minRating}
            onChange={(e) => handleFilterChange({ minRating: e.target.value })}
            className="bg-gray-800 text-gold-400 rounded-lg p-2 w-full"
            placeholder="0 - 10"
          />
        </div>

        {/* Year Range Filter */}
        <div className="mb-4">
          <label className="block text-gold-400 mb-2">Year Range</label>
          <div className="flex space-x-2">
            <input
              type="number"
              value={filters.yearFrom}
              onChange={(e) => handleFilterChange({ yearFrom: e.target.value })}
              className="bg-gray-800 text-gold-400 rounded-lg p-2 w-full"
              placeholder="From"
            />
            <input
              type="number"
              value={filters.yearTo}
              onChange={(e) => handleFilterChange({ yearTo: e.target.value })}
              className="bg-gray-800 text-gold-400 rounded-lg p-2 w-full"
              placeholder="To"
            />
          </div>
        </div>

        {/* Reset Filters Button */}
        <button
          onClick={resetFilters}
          className="mt-4 bg-red-500 text-white rounded-lg p-2 hover:bg-red-600 transition duration-300"
        >
          Reset Filters
        </button>
      </div>
    );
  };

  return (
    <div className="w-full px-4 py-8 bg-black ">
      <div className="max-w-screen-2xl mx-auto mt-12">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gold-500 tracking-wide">
            Filtered Content
          </h2>
        </div>

        <div className="flex">
          {renderFilterSidebar()}

          <div className="flex-1 ml-4">
            {loading ? (
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
                {[...Array(18)].map((_, index) => (
                  <div 
                    key={`loading-${index}`}
                    className="bg-black/10 rounded-lg animate-pulse h-64"
                  ></div>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
                {content.map(item => {
                  const CategoryIcon = getCategoryIcon(item.media_type);
                  const contentType = item.media_type === 'movie' ? 'movie' : 'tv';

                  return (
                    <Link 
                      to={`/watch/${contentType}/${item.id}`} 
                      key={`${item.id}-${Math.random().toString(36).substr(2, 9)}`} 
                      className=" group relative"
                    >
                      <div className="
                        relative overflow-hidden rounded-xl shadow-xl 
                        transition-all duration-300 
                        border border-transparent
                        group-hover:border-gold-500/50 
                        group-hover:scale-105 
                        group-hover:shadow-2xl 
                        bg-black/5">
                        {/* Category Icon Overlay */}
                        <div className="absolute top-2 left-2 z-10 
                                        bg-black/60 rounded-full 
                                        p-2 flex items-center justify-center">
                          <CategoryIcon className="text-gold-400 text-xs" />
                        </div>

                        {/* Poster Image */}
                        <div className="relative pt-[150%] overflow-hidden"> 
                          <img 
                            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
                            alt={item.title || item.name}
                            className="absolute inset-0 w-full h-full object-cover 
                                       opacity-90 group-hover:opacity-100 
                                       transition-opacity duration-300 
                                       transform group-hover:scale-110"
                          />
                          
                          {/* Hover Overlay */}
                          <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                          transition-all duration-300 flex items-center justify-center">
                            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <FaPlay className="text-gold-500 text-4xl 
                                                transform hover:scale-110 
                                                transition-transform" />
                            </div>
                          </div>
                          
                          {/* Rating Overlay */}
                          <div className="absolute top-2 right-2 
                                          bg-black/60 rounded-full 
                                          px-2 py-1 flex items-center">
                            <FaStar className="text-gold-500 mr-1" />
                            <span className="text-xs text-gold-400 font-bold">
                              {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
                            </span>
                          </div>
                        </div>

                        {/* Title Overlay */}
                        <div className="absolute bottom-0 left-0 right-0 
                                        bg-gradient-to-t from-black/80 to-transparent 
                                        p-2">
                          <h3 className="text-xs font-semibold text-gold-400 p-2 bg-black bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
                            {item.title || item.name}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
            {hasMore && !loading && (
              <button 
                onClick={loadMoreContent} 
                className="mt-4 bg-gold-500 text-black rounded-lg p-2 hover:bg-gold-600 transition duration-300"
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPage;