import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaGavel as FaMission, 
  FaUsers, 
  FaGlobeAmericas, 
  FaHeadset, 
  FaShieldAlt, 
  FaRocket 
} from 'react-icons/fa';

const AboutUs = () => {
  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 50 
    },
    visible: {
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  // Core Values
  const coreValues = [
    {
      icon: FaMission,
      title: "Our Mission",
      description: "To revolutionize entertainment by providing a seamless, personalized streaming experience that connects users with diverse, high-quality content from around the globe."
    },
    {
      icon: FaUsers,
      title: "Community First",
      description: "We believe in building a vibrant community of entertainment enthusiasts who share, discover, and celebrate content together."
    },
    {
      icon: FaGlobeAmericas,
      title: "Global Perspective",
      description: "Our curated library spans cultures, languages, and genres, bringing the world's best entertainment to your fingertips."
    }
  ];

  // Unique Selling Points
  const uniqueSellingPoints = [
    {
      icon: FaHeadset,
      title: "Personalized Experience",
      description: "Advanced AI-driven recommendations tailored to your unique viewing preferences."
    },
    {
      icon: FaShieldAlt,
      title: "Secure & Private",
      description: "State-of-the-art security measures to protect your data and ensure a safe streaming environment."
    },
    {
      icon: FaRocket,
      title: "Continuous Innovation",
      description: "Constantly evolving our platform with cutting-edge technology and user-centric features."
    }
  ];

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6 overflow-hidden"
    >
      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>

      {/* Header Section */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
        className="text-center mb-16 relative mt-14  z-10"
      >
        <h1 className="text-5xl font-bold mb-4 text-gold-500 tracking-wider">
          About Streamify
        </h1>
        <p className="max-w-2xl mx-auto text-gold-300 text-lg">
          Transforming entertainment, one stream at a time. Discover, explore, and immerse yourself in a world of endless content.
        </p>
      </motion.div>

      {/* Core Values Section */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-6xl mx-auto mb-16"
      >
        <h2 className="text-3xl font-semibold text-center mb-12 text-gold-500">
          Our Core Values
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {coreValues.map((value, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              whileHover={{ 
                scale: 1.05,
                transition: { duration: 0.2 }
              }}
              className="
                bg-zinc-800/50 
                border border-gold-500/20 
                rounded-xl 
                p-6 
                shadow-lg 
                hover:shadow-2xl 
                transition-all 
                duration-300
                text-center
              "
            >
              <div className="flex justify-center mb-4">
                <value.icon className="text-5xl text-gold-500" />
              </div>
              <h3 className="text-xl font-semibold text-gold-500 mb-4">
                {value.title}
              </h3>
              <p className="text-gray-300">
                {value.description}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Unique Selling Points */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-6xl mx-auto"
      >
        <h2 className="text-3xl font-semibold text-center mb-12 text-gold-500">
          Why Choose Streamify?
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {uniqueSellingPoints.map((point, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              whileHover={{ 
                scale: 1.05,
                transition: { duration: 0.2 }
              }}
              className="
                bg-zinc-800/50 
                border border-gold-500/20 
                rounded-xl 
                p-6 
                shadow-lg 
                hover:shadow-2xl 
                transition-all 
                duration-300
                text-center
              "
            >
              <div className="flex justify-center mb-4">
                <point.icon className="text-5xl text-gold-500" />
              </div>
              <h3 className="text-xl font-semibold text-gold-500 mb-4">
                {point.title}
              </h3>
              <p className="text-gray-300">
                {point.description}
              </p>
            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 100, 
          delay: 0.5 
        }}
        className="text-center mt-16 max-w-2xl mx-auto relative z-10" >
        <h2 className="text-3xl font-semibold text-gold-500 mb-4">
          Join Us on This Journey
        </h2>
        <p className="text-gold-300 mb-6">
          Be part of our growing community and experience the future of entertainment.
        </p>
        <a 
          href="/login" 
          className="
            bg-gold-500 text-black 
            px-6 py-3 rounded-full 
            font-bold text-lg 
            hover:bg-gold-600 
            transition-all duration-300
          "
        >
          Sign Up Now
        </a>
      </motion.div>
    </motion.div>
  );
};

export default AboutUs;