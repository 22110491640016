import axios from 'axios';

// Define the base URLs for the APIs
const TMDB_BASE_URL = 'https://api.themoviedb.org/3';


// Use the API key from the environment variable
const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;

// Fetch anime from TMDB
export const fetchAnimeFromTMDB = async () => {
  try {
    const response = await axios.get(
      `${TMDB_BASE_URL}/discover/tv`, {
        params: {
          api_key: TMDB_API_KEY,
          with_genres: 16,  // Genre ID for anime
          sort_by: 'popularity.desc'
        }
      }
    );
    return response.data.results;
  } catch (error) {
    console.error("Error fetching anime:", error);
    return [];
  }
};

// Fetch popular movies from TMDB API
export const fetchMoviesFromTMDB = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/movie/popular`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching movies from TMDB:', error);
    return [];
  }
};

// Fetch popular TV shows from TMDB API
export const fetchTVShowsFromTMDB = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/popular`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching TV shows from TMDB:', error);
    return [];
  }
};

// Fetch top-rated movies from TMDB API
export const fetchTopRatedMovies = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/movie/top_rated`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching top-rated movies from TMDB:', error);
    return [];
  }
};

// Fetch top-rated TV shows from TMDB API
export const fetchTopRatedTVShows = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/top_rated`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching top-rated TV shows from TMDB:', error);
    return [];
  }
};

// Fetch top-rated anime from TMDB API
export const fetchTopRatedAnime = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/discover/tv`, {
      params: {
        api_key: TMDB_API_KEY,
        with_genres: 16,  // Genre ID for anime
        sort_by: 'vote_average.desc',
        page,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching top-rated anime from TMDB:', error);
    return [];
  }
};

// Fetch trending content from TMDB API
export const fetchTrendingContent = async () => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/trending/all/day`, {
      params: {
        api_key: TMDB_API_KEY,
      },
    });
    return response.data.results;
  } catch (error) {
    console.error("Error fetching trending content:", error);
    return [];
  }
};

// Fetch movie details from TMDB by ID
export const fetchMovieDetails = async (tmdbId) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/movie/${tmdbId}`, {
      params: {
        api_key: TMDB_API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching movie details from TMDB:', error);
    return null;
  }
};

// Fetch TV show details from TMDB by ID
export const fetchTVShowDetails = async (tmdbId) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/${tmdbId}`, {
      params: {
        api_key: TMDB_API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching TV show details from TMDB:', error);
    return null;
  }
};

// Fetch media details (movie, tv, anime) from TMDB
export const getMediaDetails = async (id, mediaType) => {
  try {
    let response;
    if (mediaType === 'movie') {
      response = await fetchMovieDetails(id);
    } else if (mediaType === 'tv') {
      response = await fetchTVShowDetails(id);
    } else if (mediaType === 'anime') {
      // Assuming we treat anime as a TV show on TMDB
      response = await fetchTVShowDetails(id);
    } else {
      throw new Error('Unsupported media type');
    }
    return response;
  } catch (error) {
    console.error('Error fetching media details:', error);
    throw error;
  }
};

// Fetch recommended content for media (movie, tv, anime)
export const getRecommendedContent = async (id, mediaType) => {
  try {
    let response;
    if (mediaType === 'movie') {
      response = await axios.get(`${TMDB_BASE_URL}/movie/${id}/recommendations`, {
        params: {
          api_key: TMDB_API_KEY,
        },
      });
    } else if (mediaType === 'tv') {
      response = await axios.get(`${TMDB_BASE_URL}/tv/${id}/recommendations`, {
        params: {
          api_key: TMDB_API_KEY,
        },
      });
    } else if (mediaType === 'anime') {
      // Assuming anime recommendations are fetched via TV shows (if TMDB supports anime as TV shows)
      response = await axios.get(`${TMDB_BASE_URL}/tv/${id}/recommendations`, {
        params: {
          api_key: TMDB_API_KEY,
        },
      });
    } else {
      throw new Error('Unsupported media type for recommendations');
    }
    return response.data.results;
  } catch (error) {
    console.error('Error fetching recommended content:', error);
    throw error;
  }
};

// Function to get streaming servers for movies
export const getMovieServers = (tmdbId) => {
  return [
    {
      server: 'Server 1',
      url: `https://embed.su/embed/movie/${tmdbId}`,
    },
    {
      server: 'Server 2',
      url: `https://vidlink.pro/movie/${tmdbId}?primaryColor=b8962e&secondaryColor=d4af376&iconColor=b8962e&title=true&poster=true&autoplay=true&nextbutton=true`,
    },
    // Add more servers as needed
    {
      server: 'Server 3',
      url: `https://moviesapi.club/movie/${tmdbId}`,
    },
    {
      server: 'Server 4',
      url: `https://vidsrc.dev/embed/movie/${tmdbId}`,
    },
    {
      server: 'Server 5',
      url: `https://player.smashy.stream/movie/${tmdbId}`,
    },
    {
      server: 'Server 6',
      url:`https://anyembed.xyz/movie/${tmdbId}`,
    },
    {
      server: 'Server 7',
      url: `https://player.vidsrc.nl/embed/movie/${tmdbId}`,
    },
    {
      server: 'Server 8',
      url: `https://www.NontonGo.win/embed/movie/${tmdbId}`,
    },
    {
      server: 'Server 9',
      url: `https://flicky.host/embed/movie/?id=${tmdbId}`,
    },
    {
      server: 'Server 10',
      url: `https://watch.streamflix.one/movie/${tmdbId}/watch?server=1`,

    },
    {
      server: 'Server 11',
      url: `https://filmku.stream/embed/${tmdbId}`,
    },
    {
      server: 'Server 12',
      url: `https://frembed.lol/api/film.php?id=${tmdbId}`,
    },
    {
      server: 'Server 13',
      url: `https://vidsrc.icu/embed/movie/${tmdbId}`,
    },
    {
      server: 'Server 14',
      url: `https://rivestream.live/watch?type=movie&amp&id=${tmdbId}`,
    },
    


  ];
};

// Function to get streaming servers for TV shows
export const getTVShowServers = (tmdbId, seasonNumber, episodeNumber) => {
  return [
    {
      server: 'Server 1',
      url: `https://embed.su/embed/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
    },
    {
      server: 'Server 2',
      url: `https://vidlink.pro/tv/${tmdbId}/${seasonNumber}/${episodeNumber}?primaryColor=ff0044&secondaryColor=f788a6&iconColor=ff0044&title=true&poster=true&autoplay=true&nextbutton=true`,
    },
    {
      server: 'Server 3',
      url: `https://moviesapi.club/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
    },
    {
      server: 'Server 4',
      url: `https://vidsrc.dev/embed/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
    },
    {
      server: 'Server 5',
      url: `https://player.smashy.stream/tv/${tmdbId}?s=${seasonNumber}&e=${episodeNumber}`,
    },
    {
      server: 'Server 6',
      url: `https://anyembed.xyz/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
    },
    {
    server: 'Server 7',
    url: `https://player.vidsrc.nl/embed/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
  },
  {
    server: 'Server 8',
    url: `https://www.NontonGo.win/embed/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
  },
  {
    server: 'Server 9',
    url: `https://flicky.host/embed/tv/?id=${tmdbId}/${seasonNumber}/${episodeNumber}`,
  },
  {
    server: 'Server 10',
    url: `https://watch.streamflix.one/tv/${tmdbId}/watch?server=1&season=${seasonNumber}&episode=${episodeNumber}`,

  },
  {
    server: 'Server 11',
    url: `https://filmku.stream/embed/${tmdbId}`,
  },
  {
    server: 'Server 12',
    url: `https://frembed.lol/api/serie.php?id=${tmdbId}&sa=${seasonNumber}&epi=${episodeNumber}`,
  },
  {
    server: 'Server 13',
    url: `https://vidsrc.icu/embed/tv/${tmdbId}/${seasonNumber}/${episodeNumber}`,
  },
  {
    server: 'Server 14',
    url: `https://rivestream.live/watch?type=tv&amp&id=${tmdbId}&season=${seasonNumber}&episode=${episodeNumber}`,
  },

    // Add more servers as needed
  ];
};

// Function to get cast details
export const getCastDetails = async (id, mediaType) => {
  try {
    let response;
    if (mediaType === 'movie') {
      response = await axios.get(`${TMDB_BASE_URL}/movie/${id}/credits`, {
        params: {
          api_key: TMDB_API_KEY,
        },
      });
    } else if (mediaType === 'tv') {
      response = await axios.get(`${TMDB_BASE_URL}/tv/${id}/credits`, {
        params: {
          api_key: TMDB_API_KEY,
        },
      });
    } else {
      throw new Error('Unsupported media type for cast');
    }
    return response.data.cast;
  } catch (error) {
    console.error('Error fetching cast details:', error);
    return [];
  }
};
// Add this to the existing api.js file
export const fetchLatestMovies = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/movie/now_playing`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
        language: 'en-US',
      },
    });
    return response.data.results.map(movie => ({
      ...movie,
      adult: movie.adult || false, // Ensure adult property is included
    }));
  } catch (error) {
    console.error('Error fetching latest movies from TMDB:', error);
    return [];
  }
};

// fetch latest tv shows
export const fetchLatestTVShows = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/on_the_air`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
        language: 'en-US',
      }, });
    return response.data.results.map(show => ({
      ...show,
      adult: show.adult || false, // Ensure adult property is included
    }));
  } catch (error) {
    console.error('Error fetching latest TV shows from TMDB:', error);
    return [];
  }
};
// fetch latest anime
export const fetchLatestAnime = async (page = 1) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/discover/tv`, {
      params: {
        api_key: TMDB_API_KEY,
        page,
        language: 'en-US',
        with_genres: 16, // Anime genre
        sort_by: 'popularity.desc'
      },
    });
    return response.data.results.map(anime => ({
      ...anime,
      adult: anime.adult || false, // Ensure adult property is included
      media_type: 'anime' // Add media type for consistency
    }));
  } catch (error) {
    console.error('Error fetching latest anime from TMDB:', error);
    return [];
  }
};

export const getFeaturedMovies = async () => {
  const response = await axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=${TMDB_API_KEY}&language=en-US&page=1`);
  return response.data.results;
};

export const getFeaturedTVShows = async () => {
  const response = await axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=${TMDB_API_KEY}&language=en-US&page=1`);
  return response.data.results;
};

export const getSearchSuggestions = async (query) => {
  const response = await axios.get(`https://api.themoviedb.org/3/search/multi?api_key=${TMDB_API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`);
  return response.data.results;
};

export const searchContent = async (query) => {
  const response = await axios.get(`https://api.themoviedb.org/3/search/multi?api_key=${TMDB_API_KEY}&language=en-US&query=${query}&page=1&include_adult=false`);
  return response.data.results;
};

// Fetch genres from TMDB API
export const fetchGenres = async () => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/genre/movie/list`, {
      params: {
        api_key: TMDB_API_KEY,
        language: 'en-US',
      },
    });

    const tvGenresResponse = await axios.get(`${TMDB_BASE_URL}/genre/tv/list`, {
      params: {
        api_key: TMDB_API_KEY,
        language: 'en-US',
      },
    });

    // Combine movie and TV genres
    const genres = [
      ...response.data.genres,
      ...tvGenresResponse.data.genres.map((genre) => ({
        ...genre,
        media_type: 'tv', // Add media type for filtering
      })),
    ];

    return genres;
  } catch (error) {
    console.error('Error fetching genres:', error);
    return [];
  }
};
// Fetch movies sorted by release date in descending order up to the current date
export const fetchMoviesByReleaseDate = async (page = 1) => {
  try {
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];
    
    const response = await axios.get(`${TMDB_BASE_URL}/discover/movie`, {
      params: {
        api_key: TMDB_API_KEY,
        sort_by: 'release_date.desc',
        page,
        'release_date.lte': today,  // Limit results to movies released on or before today
      },
    });
    return response.data.results;
  } catch (error) {
    console.error('Error fetching movies by release date:', error);
    return [];
  }
};
// Fetch Tv shows sorted by release date in descending order up to the current date
export const fetchTVShowsByReleaseDate = async (page = 1) => {
  try {
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];
    
    const response = await axios.get(`${TMDB_BASE_URL}/discover/tv`, {
      params: {
        api_key: TMDB_API_KEY,
        sort_by: 'first_air_date.desc',
        page,
        'first_air_date.lte': today,  // Limit results to TV shows released on or
      },
      });
      return response.data.results;
      } catch (error) {
        console.error('Error fetching TV shows by release date:', error);
        return [];
   }
};
// Fetch anime sorted by release date in descending order up to the current dat
export const fetchAnimeByReleaseDate = async (page = 1) => {
  try {
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split('T')[0];
    
    const response = await axios.get(`${TMDB_BASE_URL}/discover/tv`, {
      params: {
        api_key: TMDB_API_KEY,
        sort_by: 'first_air_date.desc',
        page,
        with_genres: 16, // Anime genre
        'first_air_date.lte': today,  // Limit results to TV shows released on or
      },
      });
      return response.data.results;
      }
      catch (error) {
        console.error('Error fetching anime by release date:', error);
        return [];
      }
};

// Fetch seasons of a TV show from TMDB API
export const fetchTVShowSeasons = async (tmdbId) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/${tmdbId}`, {
      params: {
        api_key: TMDB_API_KEY,
      },
    });
    return response.data.seasons; // This should return an array of seasons
  } catch (error) {
    console.error('Error fetching TV show seasons:', error);
    return [];
  }
};

// Fetch episodes for a specific season
export const fetchEpisodesBySeason = async (tmdbId, seasonNumber) => {
  try {
    const response = await axios.get(`${TMDB_BASE_URL}/tv/${tmdbId}/season/${seasonNumber}`, {
      params: {
        api_key: TMDB_API_KEY,
      },
    });
    return response.data.episodes; // This should return an array of episodes
  } catch (error) {
    console.error('Error fetching episodes:', error);
    return [];
  }
};

// Fetch Filtered Content
export const fetchFilteredContent = async (filters) => {
  try {
    // Construct query parameters based on filters
    const params = {
      api_key: TMDB_API_KEY,
      language: 'en-US',
      page: filters.page || 1,
      sort_by: getSortMethod(filters.sortBy)
    };

    // Add genre filter if specified
    if (filters.genre) {
      params.with_genres = filters.genre;
    }

    // Add year filters
    if (filters.yearFrom) {
      params['primary_release_date.gte'] = `${filters.yearFrom}-01-01`;
    }
    if (filters.yearTo) {
      params['primary_release_date.lte'] = `${filters.yearTo}-12-31`;
    }

    // Add rating filter
    if (filters.minRating) {
      params['vote_average.gte'] = filters.minRating;
    }

    // Determine endpoint based on media type
    let endpoint = '/discover/movie';
    if (filters.type === 'tv') {
      endpoint = '/discover/tv';
      // Modify parameters for TV shows
      if (filters.yearFrom) {
        params['first_air_date.gte'] = `${filters.yearFrom}-01-01`;
        delete params['primary_release_date.gte'];
      }
      if (filters.yearTo) {
        params['first_air_date.lte'] = `${filters.yearTo}-12-31`;
        delete params['primary_release_date.lte'];
      }
    } else if (filters.type === 'anime') {
      endpoint = '/discover/tv';
      params.with_genres = 16; // Anime genre
    }

    const response = await axios.get(`${TMDB_BASE_URL}${endpoint}`, { params });
    
    return {
      results: response.data.results.map(item => ({
        ...item,
        media_type: filters.type || (item.title ? 'movie' : 'tv')
      })),
      total_pages: response.data.total_pages
    };
  } catch (error) {
    console.error('Error fetching filtered content:', error);
    throw error;
  }
};

// Helper function to get sort method
const getSortMethod = (sortBy) => {
  switch (sortBy) {
    case 'rating':
      return 'vote_average.desc';
    case 'release_date':
      return 'primary_release_date.desc';
    case 'popularity':
    default:
      return 'popularity.desc';
  }
};