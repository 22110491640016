import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaHome, 
  FaSearch, 
  FaExclamationTriangle, 
  FaRobot,
  FaCode,
  FaClipboardList,
  FaEnvelope
} from 'react-icons/fa';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { TypeAnimation } from 'react-type-animation';
import Confetti from 'react-confetti';

const NotFound = () => {
  const [errorCode, setErrorCode] = useState(404);
  const [errorMessage, setErrorMessage] = useState('Page Not Found');
  const [showDetails, setShowDetails] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const navigate = useNavigate();

  // Dynamic Error Simulation
  useEffect(() => {
    const randomErrors = [
      { code: 404, message: 'Dimension Lost', description: 'You\'ve wandered into an uncharted digital realm.' },
      { code: 403, message: 'Quantum Barrier', description: 'Access denied by interdimensional protocols.' },
      { code: 500, message: 'Multiverse Collapse', description: 'Our servers are experiencing a reality glitch.' },
      { code: 503, message: 'Temporal Anomaly', description: 'The page is caught in a time-space continuum.' }
    ];

    const randomError = randomErrors[Math.floor(Math.random() * randomErrors.length)];
    setErrorCode(randomError.code);
    setErrorMessage(randomError.message);
  }, []);

  // Responsive Window Size
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Particles Configuration
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesOptions = {
    background: {
      color: { value: "transparent" },
    },
    fpsLimit: 120,
    particles: {
      color: { value: "#b8962e" },
      links: {
        color: "#b8962e",
        distance: 150,
        enable: true,
        opacity: 0.3,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: { default: "bounce" },
        random: false,
        speed: 1,
        straight: false,
      },
      number: { 
        density: { enable: true, area: 800 },
        value: 80 
      },
      opacity: { value: 0.2 },
      shape: { type: "circle" },
      size: { value: { min: 1, max: 5 } },
    },
    detectRetina: true,
  };

  // Quick Action Buttons
  const QuickActions = () => {
    const actions = [
      {
        icon: <FaHome className="text-2xl" />,
        label: 'Return to Homebase',
        onClick: () => navigate('/home'),
        color: 'bg-gold-500 hover:bg-gold-600 text-black',
      },
      {
        icon: <FaSearch className="text-2xl" />,
        label: 'Global Search',
        onClick: () => navigate('/search'),
        color: 'bg-gold-400 hover:bg-gold-500 text-black',
      },
      {
        icon: <FaExclamationTriangle className="text-2xl" />,
        label: 'Anomaly Report',
        onClick: () => window.open('mailto:aemovieshd.requests@gmail.com'),
        color: 'bg-red-600 hover:bg-red-700 text-white',
      }
    ];

    return (
      <div className="flex space-x-4 mt-8 justify-center">
        {actions.map((action, index) => (
          <motion.button
            key={index}
            className={`
              flex items-center space-x-2 
              px-4 py-2 rounded-full 
              transition-all duration-300 
              font-semibold tracking-wider
              ${action.color}
            `}
            whileHover={{ scale: 1.05, rotate: 3 }}
            whileTap={{ scale: 0.95 }}
            onClick={action.onClick}
          >
            {action.icon}
            <span>{action.label}</span>
          </motion.button>
        ))}
      </div>
    );
  };

  // Technical Details Component
  const TechnicalDetails = () => {
    const details = [
      { icon: <FaCode />, label: 'Error Trace', value: `STRM-${errorCode}-${Math.random().toString(36).substr(2, 9).toUpperCase()}` },
      { icon: <FaClipboardList />, label: 'Timestamp', value: new Date().toISOString() },
      { icon: <FaEnvelope />, label: 'Support Ticket', value: `TICKET-${Math.random().toString(36).substr(2, 9).toUpperCase()}` }
    ];

    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
        className="mt-8 bg-black/40 rounded-xl p-6 max-w-2xl mx-auto"
      >
        {details.map((detail, index) => (
          <div 
            key={index} 
            className="flex items-center justify-between mb-4 text-gold-300 border-b border-gold-500/20 pb-2"
          >
            <div className="flex items-center space-x-3">
              {detail.icon}
              <span className="font-semibold">{detail.label}</span>
            </div>
            <code className="text-gold-500 bg-black/30 px-2 py-1 rounded-md">
              {detail.value}
            </code>
          </div>
        ))}
      </motion.div>
    );
  };

  return (
    <div className="relative min-h-screen bg-black text-white flex items-center justify-center overflow-hidden font-sans">
      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
      />

      {/* Confetti Effect */}
      <Confetti
        width={windowSize.width}
        height={windowSize.height}
        numberOfPieces={errorCode === 404 ? 200 : 0}
        recycle={false}
        colors={['#b8962e', '#f6ad55', '#ed8936']}
      />

      {/* Content Container */}
      <motion.div 
        className="relative z-10 text-center px-6 mt-14"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Error Code with Typewriter Effect */}
        <motion.div
          className="text-9xl font-bold text-gold-500"
          initial={{ rotateX: -90 }}
          animate={{ rotateX: 0 }}
          transition={{ type: "spring", stiffness: 100 }} >
          
          <TypeAnimation
  sequence={[
    '', // Start with an empty string
    1000, // Wait for 1 second
    errorCode.toString(), // Type out the error code
    2000, // Pause after typing
    '', // Clear the text
    400 // Short pause before restarting
  ]}
  wrapper="span"
  speed={15}
  repeat={Infinity}
  cursor={true}
  style={{ display: 'inline-block' }}
/>
        </motion.div>

        {/* Error Message */}
        <motion.h1 
          className="text-4xl font-bold mt-4 text-gold-400 flex items-center justify-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <FaRobot className="mr-4" /> {errorMessage}
        </motion.h1>

        {/* Description */}
        <motion.p 
          className="text-xl text-gray-300 mt-4 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          Oops! It seems like you've stumbled into a digital void. 
          The page you're looking for might have been moved, deleted, 
          or never existed in the first place.
        </motion.p>

        {/* Quick Action Buttons */}
        <QuickActions />

        {/* Technical Details */}
        <AnimatePresence>
          {showDetails && <TechnicalDetails />}
        </AnimatePresence>

        {/* Toggle Details Button */}
        <motion.button
          className="mt-6 text-gold-500 hover:underline"
          onClick={() => setShowDetails(!showDetails)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          {showDetails ? 'Hide Technical Details' : 'Show Technical Details'}
        </motion.button>

        {/* Additional Context */}
        <motion.div
          className="mt-6 text-sm text-gray-500 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7 }}
        >
          <p>
            Need help? Contact our support team at{' '}
            <a 
              href="mailto:support@streamify.com" 
              className="text-gold-500 hover:underline"
            >
              aemovieshd.requests@gmail.com
            </a>
          </p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default NotFound;