import React from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
  useLocation, 
  useNavigate
} from 'react-router-dom';
import { motion } from 'framer-motion';

// Import components and pages directly
import LoaderSpinner from './components/LoaderSpinner';
import NotFound from './pages/NotFound';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

// Import pages directly
import IndexPage from './pages/IndexPage';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import WatchPage from './pages/WatchPage';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Movies from './pages/Movies';
import TVShows from './pages/TvShows';
import Anime from './pages/Anime';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TermsAndServices from './pages/Terms';
import DMCA from './pages/DCMA';
import PrivacyPolicy from './pages/Privacy';
import RecoverPassword from './services/RecoverPassword';
import FilterPage from './pages/Filter';
import AnimeWatchPage from './pages/AnimeWatchPage';

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

// Loading Context
const LoadingContext = React.createContext({
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {}
});

// Loading Provider Component
const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const startLoading = React.useCallback(() => setIsLoading(true), []);
  const stopLoading = React.useCallback(() => setIsLoading(false), []);

  return (
    <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>
      {children}
      {isLoading && <LoaderSpinner />}
    </LoadingContext.Provider>
  );
};

// Page Transition Wrapper
const PageTransition = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
  >
    {children}
  </motion.div>
);

// Create a PrivateRoute component to handle redirection
const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || JSON.parse(sessionStorage.getItem('userInfo'));

  React.useEffect(() => {
    if (!userInfo) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [userInfo, navigate]);

  return userInfo ? element : null;
};

// Wrapper component to handle routing logic
const AppRoutes = () => {
  const location = useLocation();
  const isIndex = location.pathname === '/';
  const isFilter = location.pathname === '/filter';

  return (
    <>
      <div className={`${isIndex ? "hidden" : "block"} ${isFilter ? "bg-black" : ""}`}>
        <NavBar />
      </div>

      <Routes>
        <Route path="/" element={<PageTransition><IndexPage /></PageTransition>} />
        <Route path="/home" element={<PageTransition><HomePage /></PageTransition>} />
        <Route path="/search" element={<PageTransition><SearchPage /></PageTransition>} />
        <Route path="/watch/:mediaType/:id" element={<PageTransition><WatchPage /></PageTransition>} />
        <Route path="/watch/anime/:id" element={<PageTransition><AnimeWatchPage /></PageTransition>} />
        <Route path="/login" element={<PageTransition><Login /></PageTransition>} />
        <Route path="/profile" element={<PrivateRoute element={<PageTransition><Profile /></PageTransition>} />} />
        <Route path="/movies" element={<PageTransition><Movies /></PageTransition>} />
        <Route path="/tv" element={<PageTransition><TVShows /></PageTransition>} />
        <Route path="/anime" element={<PageTransition><Anime /></PageTransition>} />
        <Route path="/about" element={<PageTransition><AboutUs /></PageTransition>} />
        <Route path="/contact" element={<PageTransition><ContactUs /></PageTransition>} />
        <Route path="/terms" element={<PageTransition><TermsAndServices /></PageTransition>} />
        <Route path="/dcma" element={<PageTransition><DMCA /></PageTransition>} />
        <Route path="/privacy" element={<PageTransition><PrivacyPolicy /></PageTransition>} />
        <Route path="/recover-password" element={<PageTransition><RecoverPassword /></PageTransition>} />
        <Route path="/filter" element={<PageTransition><FilterPage /></PageTransition>} />
        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <div className='bg-black'>
        <Footer />
      </div>
    </>
  );
};

// Main App component
const App = () => {
  return (
    <ErrorBoundary>
      <LoadingProvider>
        <Router>
          <AppRoutes />
        </Router>
      </LoadingProvider>
    </ErrorBoundary>
  );
};

export default App;

// Export loading hook for use in other components
export const useLoading = () => {
  const context = React.useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};