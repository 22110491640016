// src/services/search.js
import axios from 'axios';
import { searchAnime } from './Consumet';

// TMDB API base URL and key
const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_BASE_URL = 'https://api.themoviedb.org/3/';
const CONSUMET_BASE_URL = 'https://consumet-api-omega-one.vercel.app';

// Existing TMDB search functions
export const getSearchSuggestions = async (query) => {
  if (!query) return [];
  try {
    const response = await axios.get(`${TMDB_BASE_URL}search/multi`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'en-US',
        include_adult: true,
      },
    });
    return response.data.results || [];
  } catch (error) {
    console.error('Error fetching search suggestions', error);
    return [];
  }
};

// Enhanced search content function to include anime
export const searchContent = async (query) => {
  if (!query) return { movies: [], tvShows: [], anime: [] };
  try {
    // Fetch results from TMDB
    const tmdbResponse = await axios.get(`${TMDB_BASE_URL}search/multi`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'en-US',
        include_adult: true,
      },
    });

    // Fetch anime results from Consumet
    const animeResults = await searchAnime(query);

    // Process TMDB results
    const movies = tmdbResponse.data.results.filter(item => item.media_type === 'movie');
    const tvShows = tmdbResponse.data.results.filter(item => item.media_type === 'tv');
    
    // Process and enhance anime results
    const processedAnimeResults = animeResults.map(anime => ({
      id: anime.id,
      title: anime.title.english || anime.title.romaji,
      description: anime.description,
      image: anime.image,
      media_type: 'anime',
      rating: anime.rating,
      genres: anime.genres,
      type: anime.type,
      status: anime.status,
      releaseDate: anime.releaseDate,
      // You can add more mapping as needed
    }));

    return { 
      movies, 
      tvShows, 
      anime: processedAnimeResults 
    };
  } catch (error) {
    console.error('Error fetching search results', error);
    return { movies: [], tvShows: [], anime: [] };
  }
};

// New comprehensive search function that combines all sources
export const comprehensiveSearch = async (query) => {
  if (!query) return { tmdb: [], anime: [] };
  try {
    // Parallel searches
    const [tmdbResponse, animeResults] = await Promise.all([
      axios.get(`${TMDB_BASE_URL}search/multi`, {
        params: {
          api_key: TMDB_API_KEY,
          query: query,
          language: 'en-US',
          include_adult: true,
        },
      }),
      searchAnime(query)
    ]);

    // Process TMDB results
    const tmdbResults = tmdbResponse.data.results.map(item => ({
      ...item,
      source: 'tmdb'
    }));

    // Process Anime Results
    const processedAnimeResults = animeResults.map(anime => ({
      id: anime.id,
      title: anime.title.english || anime.title.romaji,
      image: anime.image,
      media_type: 'anime',
      rating: anime.rating,
      source: 'consumet',
      description: anime.description,
      genres: anime.genres
    }));

    return {
      tmdb: tmdbResults,
      anime: processedAnimeResults
    };
  } catch (error) {
    console.error('Comprehensive search error:', error);
    return { tmdb: [], anime: [] };
  }
};

// Advanced Search Function with Multiple Sources
export const advancedMultiSearch = async (params) => {
  const { 
    query, 
    type = 'all', 
    genres = [], 
    minRating = 0, 
    yearFrom, 
    yearTo 
  } = params;

  try {
    const results = {
      movies: [],
      tvShows: [],
      anime: []
    };

    // TMDB Search
    if (type === 'all' || type === 'movies' || type === 'tv') {
      const tmdbResponse = await axios.get(`${TMDB_BASE_URL}search/multi`, {
        params: {
          api_key: TMDB_API_KEY,
          query: query,
          language: 'en-US',
          include_adult: false,
        },
      });

      // Filter TMDB results based on additional parameters
      results.movies = tmdbResponse.data.results
        .filter(item => 
          (type === 'all' || item.media_type === type) &&
          (minRating ? item.vote_average >= minRating : true) &&
          (yearFrom ? parseInt(item.release_date?.split('-')[0] || 0) >= yearFrom : true) &&
          (yearTo ? parseInt(item.release_date?.split('-')[0] || 9999) <= yearTo : true)
        );
    }

    // Anime Search
    if (type === 'all' || type === 'anime') {
      const animeResults = await searchAnime(query);
      
      results.anime = animeResults.filter(anime => 
        (genres.length === 0 || genres.some(genre => anime.genres.includes(genre))) &&
        (minRating ? anime.rating >= minRating : true) &&
        (yearFrom ? parseInt(anime.releaseDate?.split('-')[0] || 0) >= yearFrom : true) &&
        (yearTo ? parseInt(anime.releaseDate?.split('-')[0] || 9999) <= yearTo : true)
      );
    }

    return results;
  } catch (error) {
    console.error('Advanced multi-source search error:', error);
    return { movies: [], tvShows: [], anime: [] };
  }
};