import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  FaBars, 
  FaSearch, 
  FaStar, 
  FaExternalLinkAlt,
  FaKeyboard  // New icon for shortcut
} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { getSearchSuggestions } from '../services/search';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null);

  // Ctrl+K Shortcut Handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Ctrl+K or Cmd+K (for Mac)
      if ((e.ctrlKey || e.metaKey) && e.key === 'k') {
        e.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Existing search functionality remains the same
  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      const results = await getSearchSuggestions(query);
      setSuggestions(results.slice(0, 5)); // Limit suggestions to 5
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (id, mediaType) => {
    navigate(`/watch/${mediaType}/${id}`);
    setSearchQuery('');
    setSuggestions([]);
  };

  const handleViewMore = () => {
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  // Hamburger Menu Animation Variants
  const hamburgerVariants = {
    closed: { rotate: 0 },
    open: { rotate: 90 }
  };
  

  // Dropdown Menu Animation Variants
  const menuVariants = {
    hidden: { 
      opacity: 0, 
      y: -20,
      scale: 0.95
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  return (
    <nav className={`
      fixed top-0 left-0 w-full 
      bg-black/60 backdrop-blur-md 
      text-gold-300 p-4 rounded-b-lg
      flex flex-wrap justify-between 
      items-center shadow-lg z-50 
      transition-all duration-300
    `}>
      {/* Left Section */}
      <div className="flex items-center justify-between w-full sm:w-auto">
        {/* Hamburger Menu Button with Framer Motion */}
        <motion.button
  onClick={() => setIsOpen(!isOpen)}
  className="text-gold-500 p-2 rounded-md focus:outline-none transform flex flex-col justify-center items-center space-y-1 hover:text-gold-600"
  initial={false}
  animate={isOpen ? "open" : "closed"}
>
  <motion.div
    variants={{
      closed: { rotate: 0, y: 0 },
      open: { rotate: 45, y: 8 }
    }}
    transition={{ 
      duration: 0.1,  // Reduced duration
      type: "tween",  // Use tween for instant-like movement
      ease: "easeOut"
    }}
    className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
  />
  <motion.div
    variants={{
      closed: { opacity: 1 },
      open: { opacity: 0 }
    }}
    transition={{ 
      duration: 0.1,  // Very quick opacity change
      ease: "linear"
    }}
    className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
  />
  <motion.div
    variants={{
      closed: { rotate: 0, y: 0 },
      open: { rotate: -45, y: -8 }
    }}
    transition={{ 
      duration: 0.1,  // Reduced duration
      type: "tween",  // Use tween for instant-like movement
      ease: "easeOut"
    }}
    className="w-7 h-0.5 bg-gold-500 transition-all duration-300 transform"
  />
</motion.button>

        {/* Logo with Hover Effect */}
        <Link 
          to="/" 
          className="
            text-gold-500 font-bold text-2xl 
            hover:text-gold-400 
            transition-colors 
            duration-300 
            ml-4
          "
        >
          Streamify
        </Link>

        {/* Login Button for Mobile */}
        <Link 
          to="/login" 
          className="
            sm:hidden border border-gold-600 
            text-gold-500 rounded-full 
            px-4 py-1 
            hover:bg-gold-600 hover:text-black 
            transition duration-500
          "
        >
          Login →
        </Link>
      </div>

      {/* Dropdown Menu with Framer Motion */}
      <AnimatePresence>
        {isOpen && (
          <motion.div 
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={menuVariants}
            className="
              absolute top-full mt-2 left-4 
              bg-black/80 backdrop-blur-lg 
              text-gold-300 rounded-lg 
              shadow-2xl w-40 py-2 z-40
            "
          >
            {[
              { path: '/home', label: 'Home' },
              { path: '/movies', label: 'Movies' },
              { path: '/tv', label: 'TV Shows' },
              { path: '/anime', label: 'Anime' },
              { path: '/filter', label: 'Filter' },
            ].map((item) => (
              <Link 
                key={item.path}
                to={item.path} 
                className="
                  block px-4 py-2 
                  hover:bg-gold-500/20 
                  hover:text-gold-400 
                  transition-colors
                "
              >
                {item.label}
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <form 
  onSubmit={handleSearchSubmit} 
  className="
    relative order-last sm:order-none 
    max-w-lg w-full mt-2 sm:mt-0 mx-4 
    group
  "
>
  <motion.div 
    className="
      flex items-center 
      bg-gray-800 text-black 
      rounded-full px-4 py-2 
      shadow-sm border border-gray-900 
      focus-within:ring-2 focus-within:ring-gold-500 
      transition-all duration-300
    "
    initial={{ scale: 1 }}
    whileFocus={{ 
      scale: 1.02,
      boxShadow: "0 0 0 3px rgba(216, 180, 54, 0.5)"
    }}
    transition={{ 
      type: "spring", 
      stiffness: 300, 
      damping: 20 
    }}
  >
    <FaSearch className="mr-2 text-gold-600" />
    <input
      ref={searchInputRef}
      type="text"
      value={searchQuery}
      onChange={handleSearchChange}
      onFocus={() => setIsSearchFocused(true)}
      onBlur={() => setIsSearchFocused(false)}
      placeholder="Search for movies, TV shows, anime..."
      className="
        bg-transparent text-gold-500 
        placeholder-gray-500 
        focus:outline-none w-full
      "
    />
    
    {/* Ctrl+K Shortcut Indicator */}
    <motion.div 
      className="
        ml-2 flex items-center 
        bg-gold-500/20 text-gold-400 
        px-2 py-1 rounded-md 
        text-xs font-semibold
      "
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ 
        type: "spring", 
        stiffness: 300, 
        damping: 20 
      }}
    >
      <FaKeyboard className="mr-1" />
      Ctrl+K
    </motion.div>
  </motion.div>

  {/* Suggestions Dropdown with Framer Motion */}
  <AnimatePresence>
    {suggestions.length > 0 && (
      <motion.div 
        initial={{ 
          opacity: 0, 
          y: -20,
          scale: 0.95 
        }}
        animate={{ 
          opacity: 1, 
          y: 0,
          scale: 1 
        }}
        exit={{ 
          opacity: 0, 
          y: -20,
          scale: 0.95 
        }}
        transition={{ 
          type: "spring", 
          stiffness: 300, 
          damping: 20 
        }}
        className="
          absolute bg-gray-800 
          text-gold-300 w-full mt-2 
          rounded-lg shadow-xl 
          border border-gray-900 
          z-10 p-2
        "
      >
        {suggestions.map((suggestion) => (
          <motion.div
            key={suggestion.id}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            transition={{ 
              type: "spring", 
              stiffness: 300, 
              damping: 20 
            }}
            className="flex items-center p-3 hover:bg-gold-500 hover:text-zinc-900 cursor-pointer transition-all duration-300 ease-in-out rounded-lg"
            onClick={() => handleSuggestionClick(suggestion.id, suggestion.media_type)}
            whileHover={{ 
              scale: 1.02,
              backgroundColor: 'rgba(216, 180, 54, 0.7)' 
            }}
            whileTap={{ scale: 0.98 }}
          >
            {suggestion.poster_path && (
              <motion.img
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 300, 
                  damping: 20 
                }}
                src={`https://image.tmdb.org/t/p/w92${suggestion.poster_path}` || 'https://clipartcraft.com/images/no-logo-placeholder-2.png'}
                alt={suggestion.title || suggestion.name}
                className="w-10 h-14 mr-3 rounded-md"
              />
            )}
            <div className="flex flex-col text-xs">
              <span className="font-semibold">{suggestion.title || suggestion.name}</span>
              <div className="flex items-center space-x-2 text-[10px] text-gray-400">
                <span>{suggestion.media_type === 'movie' ? 'Movie' : 'TV Show'}</span>
                <span>•</span>
                <span>{suggestion.release_date ? suggestion.release_date.split('-')[0] : suggestion.first_air_date ? suggestion.first_air_date.split('-')[0] : 'N/A' }</span>
                <span>•</span>
                <span className="flex items-center text-yellow-400">
                  <FaStar className="mr-1" /> {suggestion.vote_average || 'N/A'}
                </span>
              </div>
            </div>
          </motion.div>
        ))}
        
        <motion.button
          onClick={handleViewMore}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full mt-1 text-center text-black bg-gold-400 py-1 rounded-lg font-semibold hover:bg-gold-600 transition duration-300 border-2 border-gold-500 shadow-md shadow-neutral-900 flex items-center justify-center"
        >
          View More <FaExternalLinkAlt className="inline ml-2" />
        </motion.button>
      </motion.div>
    )}
  </AnimatePresence>
</form>
      {/* Login Button for Desktop */}
      <Link 
        to="/login" 
        className="
          hidden sm:block border border-gold-600 
          text-gold-500 rounded-full 
          px-4 py-1 
          hover:bg-gold-600 hover:text-black 
          transition duration-600
        " >
        Login →
      </Link>
    </nav>
  );
};

export default NavBar;