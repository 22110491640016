import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaFilter, 
  FaCalendarAlt, 
  FaTags, 
  FaTimes, 
  FaChevronDown 
} from 'react-icons/fa';
import { fetchGenres } from '../services/api';

const FilterComponent = ({ onFilterChange }) => {
  // State Management
  const [genres, setGenres] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    genre: '',
    year: '',
    type: '',
    rating: '',
    sortBy: ''
  });

  // Refs
  const filterRef = useRef(null);

  // Fetch Genres on Component Mount
  useEffect(() => {
    const loadGenres = async () => {
      try {
        const genreData = await fetchGenres();
        setGenres(genreData);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };
    loadGenres();

    // Click outside handler
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Update Filter Handler
  const handleFilterUpdate = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  // Reset Filters
  const resetFilters = () => {
    const resetState = {
      genre: '',
      year: '',
      type: '',
      rating: '',
      sortBy: ''
    };
    setFilters(resetState);
    onFilterChange(resetState);
  };

  // Render Filter Dropdown
  const renderFilterDropdown = () => {
    return (
      <motion.div 
        ref={filterRef}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="absolute top-full left-0 w-full mt-2 bg-zinc-800 rounded-xl shadow-2xl border border-gold-500/20 z-50 p-6"
      >
        <div className="grid md:grid-cols-3 gap-4">
          {/* Genre Filter */}
          <div>
            <label className="block text-gold-400 mb-2 flex items-center">
              <FaTags className="mr-2 text-gold-500" />
              Genre
            </label>
            <select
              value={filters.genre}
              onChange={(e) => handleFilterUpdate({ genre: e.target.value })}
              className="w-full bg-zinc-900 text-gold-300 border border-gold-500/30 rounded-lg p-2 focus:ring-2 focus:ring-gold-500 transition-all"
            >
              <option value="">All Genres</option>
              {genres.map((genre) => (
                <option key={genre.id} value={genre.id}>
                  {genre.name}
                </option>
              ))}
            </select>
          </div>

          {/* Year Filter */}
          <div>
            <label className="block text-gold-400 mb-2 flex items-center">
              <FaCalendarAlt className="mr-2 text-gold-500" />
              Release Year
            </label>
            <input
              type="number"
              min="1900"
              max={new Date().getFullYear()}
              value={filters.year}
              onChange={(e) => handleFilterUpdate({ year: e.target.value })}
              placeholder="Year"
              className="w-full bg-zinc-900 text-gold-300 border border-gold-500/30 rounded-lg p-2 focus:ring-2 focus:ring-gold-500 transition-all"
            />
          </div>

          {/* Media Type Filter */}
          <div>
            <label className="block text-gold-400 mb-2 flex items-center">
              <FaFilter className="mr-2 text-gold-500" />
              Media Type
            </label>
            <select
              value={filters.type}
              onChange={(e) => handleFilterUpdate({ type: e.target.value })}
              className="w-full bg-zinc-900 text-gold-300 border border-gold-500/30 rounded-lg p-2 focus:ring-2 focus:ring-gold-500 transition-all"
            >
              <option value="">All Types</option>
              <option value="movie">Movies</option>
              <option value="tv">TV Shows</option>
              <option value="anime">Anime</option>
            </select>
          </div>
        </div>

        {/* Additional Filters */}
        <div className="grid md:grid-cols-3 gap-4 mt-4">
          {/* Rating Filter */}
          <div>
            <label className="block text-gold-400 mb-2">
              Minimum Rating
            </label>
            <select
              value={filters.rating}
              onChange={(e) => handleFilterUpdate({ rating: e.target.value })}
              className="w-full bg-zinc-900 text-gold-300 border border-gold-500/30 rounded-lg p-2"
            >
              <option value="">Any Rating</option>
              <option value="7">7+ ⭐</option>
              <option value="8">8+ ⭐</option>
              <option value="9">9+ ⭐</option>
            </select>
          </div>

          {/* Sort By Filter */}
          <div>
            <label className="block text-gold-400 mb-2">
              Sort By
            </label>
            <select
              value={filters.sortBy}
              onChange={(e) => handleFilterUpdate({ sortBy: e.target.value })}
              className="w-full bg-zinc-900 text-gold-300 border border-gold-500/30 rounded-lg p-2"
            >
              <option value="">Default</option>
              <option value="popularity">Popularity</option>
              <option value="rating">Rating</option>
              <option value="release_date">Release Date</option>
            </select>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between mt-6">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={resetFilters}
            className="bg-red-600/20 text-red-400 px-4 py-2 rounded-lg hover:bg-red-600/40 transition-all flex items-center"
          >
            <FaTimes className="mr-2" /> Reset
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsOpen(false)}
            className="bg-gold-500/20 text-gold-400 px-4 py-2 rounded-lg hover:bg-gold-500/40 transition-all"
          >
            Apply Filters
          </motion.button>
        </div>
      </motion.div>
    );
  };

  return (
    <motion.div 
      className="relative w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center bg-gold-500 text-white px-4 py-2 rounded-lg shadow-lg transition-all"
      >
        <FaFilter className="mr-2" /> Filters <FaChevronDown className={`ml-2 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </motion.button>

      <AnimatePresence>
        {isOpen && renderFilterDropdown()}
      </AnimatePresence>
    </motion.div>
  );
};

export default FilterComponent;