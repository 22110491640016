import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaUser, 
  FaLock, 
  FaEnvelope, 
  FaEye, 
  FaEyeSlash, 
  FaFingerprint 
} from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [formStage, setFormStage] = useState('login'); // 'login', 'recovery', 'reset'

  const navigate = useNavigate();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  // Advanced form validation
  const validateForm = () => {
    if (!email || !password || !username) {
      setErrorMessage('Please fill in all fields');
      return false;
    }
    
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address');
      return false;
    }

    // Password strength check
    if (password.length < 8) {
      setErrorMessage('Password must be at least 8 characters long');
      return false;
    }

    return true;
  };

  // Sophisticated login handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    // Simulate sophisticated authentication
    setIsAnimating(true);
    
    setTimeout(() => {
      // Generate a more complex recovery code
      const generateAdvancedRecoveryCode = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        return Array.from(
          crypto.getRandomValues(new Uint32Array(6)), 
          x => chars[x % chars.length]
        ).join('');
      };

      const recoveryCode = generateAdvancedRecoveryCode();
      
      // Store user info with enhanced security
      const userInfo = {
        email,
        username,
        recoveryCode,
        profilePicture: `https://ui-avatars.com/api/?name=${username}&background=2C3E50&color=ECF0F1&bold=true`
      };

      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      
      navigate('/profile');
    }, 1500);
  };

  // Animated background effect
  const BackgroundEffect = () => (
    <motion.div 
      className="absolute inset-0 z-0 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {[...Array(20)].map((_, i) => (
        <motion.div 
          key={i}
          className="absolute bg-gold-500/10 rounded-full"
          initial={{ 
            scale: 0, 
            top: `${Math.random() * 100}%`, 
            left: `${Math.random() * 100}%` 
          }}
          animate={{ 
            scale: [0, 2, 0],
            rotate: 360
          }}
          transition={{
            duration: Math.random() * 10 + 5,
            repeat: Infinity,
            delay: Math.random() * 2
          }}
          style={{
            width: `${Math.random() * 200 + 50}px`,
            height: `${Math.random() * 200 + 50}px`
          }}
        />
      ))}
    </motion.div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center relative bg-gradient-to-br from-black via-gray-900 to-black overflow-hidden">
      <BackgroundEffect />
      
      <motion.div 
        className="relative z-10 w-full max-w-md p-8 bg-black/60 backdrop-blur-xl rounded-2xl shadow-2xl border border-gold-500/20 mt-14 "
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "spring", stiffness: 120 }}
      >
        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gold-400 to-gold-600">
            Streamify
          </h1>
          <p className="text-gold-300 mt-2">Secure Access Portal</p>
        </motion.div>

        <AnimatePresence mode="wait">
          {isAnimating ? (
            <motion.div 
              key="loading"
              className="flex justify-center items-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="animate-spin w-16 h-16 border-t-4 border-gold-500 rounded-full"></div>
            </motion.div>
          ) : (
            <motion.form 
              key="login-form"
              onSubmit={handleSubmit}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="space-y-6"
            >
              {/* Input Fields with Enhanced Design */}
              <motion.div 
                className="relative"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaUser className="text-gold-500" />
                </div>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                  className="w-full pl-10 pr-4 py-3 bg-black/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                />
              </motion.div>

              <motion.div 
                className="relative"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaEnvelope className="text-gold-500" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  className="w-full pl-10 pr-4 py-3 bg-black/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                />
              </motion.div>

               <motion.div 
                className="relative"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FaLock className="text-gold-500" />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="w-full pl-10 pr-4 py-3 bg-black/40 border border-gold-500/30 rounded-xl text-gold-300 focus:outline-none focus:ring-2 focus:ring-gold-500 transition-all duration-300"
                />
                <div 
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash className="text-gold-500" /> : <FaEye className="text-gold-500" />}
                </div>
              </motion.div>

              {/* Error Message Display */}
              {errorMessage && (
                <motion.div 
                  className="text-red-500 text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {errorMessage}
                </motion.div>
              )}

              {/* Submit Button */}
              <motion.button 
                type="submit"
                className="w-full py-3 bg-gold-500 rounded-xl text-black font-semibold hover:bg-gold-600 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Log In
              </motion.button>
            </motion.form>
          )}
        </AnimatePresence>

        {/* Footer Links */}
        <div className="mt-6 text-center">
          <a href="#" className="text-gold-300 hover:underline">Forgot Password?</a>
        </div>
      </motion.div>
    </div>
  );
};

export default Login;