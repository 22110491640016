import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchMoviesByReleaseDate } from '../services/api';
import Pagination from '../components/Pagination';
import FilterComponent from '../components/Filter'; // Import the Filter component
import { Link } from 'react-router-dom';
import { FaPlay, FaStar } from 'react-icons/fa';
import LoaderSpinner from '../components/LoaderSpinner';

const Movies = () => {
  const [movies, setMovies] = useState([]);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    genre: '',
    year: '',
    type: 'movie', // Default to movies
    rating: '',
    sortBy: ''
  });

  // Fetch Movies and Apply Initial Load
  useEffect(() => {
    const loadMovies = async () => {
      try {
        setLoading(true);
        const data = await fetchMoviesByReleaseDate(currentPage);
        setMovies(data);
        setFilteredMovies(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
        setLoading(false);
      }
    };
    loadMovies();
  }, [currentPage]);

  // Filter and Sort Movies
  useEffect(() => {
    let result = [...movies];

    // Genre Filter
    if (filters.genre) {
      result = result.filter(movie => 
        movie.genre_ids?.includes(Number(filters.genre))
      );
    }

    // Year Filter
    if (filters.year) {
      result = result.filter(movie => 
        movie.release_date?.startsWith(filters.year)
      );
    }

    // Rating Filter
    if (filters.rating) {
      result = result.filter(movie => 
        movie.vote_average >= Number(filters.rating)
      );
    }

    // Sort Movies
    if (filters.sortBy) {
      switch (filters.sortBy) {
        case 'popularity':
          result.sort((a, b) => b.popularity - a.popularity);
          break;
        case 'rating':
          result.sort((a, b) => b.vote_average - a.vote_average);
          break;
        case 'release_date':
          result.sort((a, b) => 
            new Date(b.release_date) - new Date(a.release_date)
          );
          break;
        default:
          break;
      }
    }

    setFilteredMovies(result);
  }, [movies, filters]);

  // Page Change Handler
  const handlePageChange = (page) => {
    if (page > 0) {
      setCurrentPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Filter Change Handler
  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  };

  // Container Variants for Staggered Animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.9,
      y: 50 
    },
    visible: {
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 15
      }
    }
  };

  // Render Content
  const renderContent = () => {
    if (loading) {
      return <LoaderSpinner type="cosmic" />;
    }

    if (filteredMovies.length === 0) {
      return (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="col-span-full text-center text-gold-400"
        >
          No movies found. Try adjusting your filters.
        </motion.div>
      );
    }

    return (
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6"
      >
        <AnimatePresence>
          {filteredMovies.map((movie) => (
            <motion.div
              key={movie.id}
              variants={itemVariants}
              className="group relative"
            >
              {/* Movie card component (same as previous implementation) */}
              <Link to={`/watch/movie/${movie.id}`} className="group">
                <motion.div 
                  className="relative overflow-hidden rounded-2xl shadow-lg 
                             transition-all duration-400 
                             border border-transparent 
                             bg-gradient-to-b from-zinc-900 to-black 
                             hover:border-gold-500/30 
                             hover:shadow-2xl"
                  whileHover={{
                    scale: 1.05,
                    transition: { 
                      duration: 0.3,
                      type: "spring",
                      stiffness: 300
                    }
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  {/* Poster Image Container */}
                  <div className="relative pt-[150%] overflow-hidden rounded-2xl">
                    {/* Background Blur Effect */}
                    <div 
                      className="absolute inset-0 bg-cover bg-center blur-sm opacity-30"
                      style={{ 
                        backgroundImage: `url(https://image.tmdb.org/t/p/w300/${movie.poster_path})`,
                        transform: 'scale(1.1)'
                      }}
                    />

                    {/* Main Poster Image */}
                    <motion.img 
                      src={`https://image.tmdb.org/t/p/w300/${movie.poster_path}` || `https://clipartcraft.com/images/no-logo-placeholder-2.png`}
                      alt={movie.title}
                      className="absolute inset-0 w-full h-full object-cover rounded-2xl"
                      initial={{ opacity: 0.8, scale: 1 }}
                      whileHover={{ 
                        opacity: 1, 
                        scale: 1.1,
                        transition: { 
                          duration: 0.3,
                          type: "spring",
                          stiffness: 200
                        }
                      }}
                    />

                    {/* Hover Overlay */}
                    <motion.div 
                      className="absolute inset-0 bg-black/0 group-hover:bg-black/40 
                                  transition-all duration-300 flex items-center justify-center"
                      initial={{ opacity: 0 }}
                      whileHover={{ opacity: 1 }}
                    >
                      <motion.div 
                        className="opacity-0 group-hover:opacity-100 
                                    transition-opacity duration-300"
                        whileHover={{ 
                          scale: 1.2,
                          rotate: 360,
                          transition: { duration: 0.5 }
                        }}
                      >
                        <FaPlay className="text-gold-500 text-5xl 
                                            drop-shadow-[0_0_10px_rgba(216,180,54,0.7)]
                                            transform hover:scale-110 
                                            transition-transform" />
                      </motion.div>
                    </motion.div>

                    {/* Rating Overlay */}
                    <motion.div 
                      className="absolute top-3 right-3 
                                  bg-black/70 rounded-full 
                                  px-3 py-1 flex items-center"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <FaStar className="text-gold-500 mr-2 animate-pulse" />
                      <span className="text-sm text-gold-400 font-bold">
                        {movie.vote_average ? movie.vote_average.toFixed(1) : 'N/A'}
                      </span>
                    </motion.div>
                  </div>

                  {/* Title Overlay */}
                  <motion.div 
                    className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to- transparent 
                                p-3 rounded-b-2xl"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <h3 className="text-sm font-semibold text-gold-400 
                                   truncate group-hover:text-gold-300 
                                   transition-colors duration-300 
                                   px-2 py-1 bg-black/50 rounded-full 
                                   group-hover:bg-gold-500/20">
                      {movie.title}
                    </h3>
                  </motion.div>
                </motion.div>
              </Link>
            </motion.div>
          ))}
        </AnimatePresence>
      </motion.div>
    );
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-b from-gray-900 to-black min-h-screen p-6"
    >
      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>
  
      {/* Header Section with Filters */}
      <motion.div 
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-40 mb-10 mt-14 flex flex-col md:flex-row justify-between items-center"
      >
        <h1 className="text-3xl font-bold mb-4 md:mb-0 text-gold-500">
          Movies Collection
        </h1>
        
        <FilterComponent onFilterChange={handleFilterChange}/>
      </motion.div>

      {/* Content Area */}
      <div className="relative z-10">
        {renderContent()}
      </div>

      {/* Pagination */}
      <motion.div 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="mt-10 relative z-10"
      >
        <Pagination 
          currentPage={currentPage} 
          onPageChange={handlePageChange} 
        />
      </motion.div>
    </motion.div>
  );
};

export default Movies;