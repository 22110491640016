import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { 
  FaGavel, 
  FaShieldAlt, 
  FaBalanceScale, 
  FaCheckCircle,
  FaCheck,
  FaExclamationTriangle,
  FaUserShield,
  FaLock
} from 'react-icons/fa';

const TermsAndServices = () => {
  const navigate = useNavigate();
  const [isAccepted, setIsAccepted] = useState(false);
  const TERMS_VERSION = '1.0';

  // Check previous acceptance on component mount
  useEffect(() => {
    const termsAccepted = localStorage.getItem('termsAccepted');
    const acceptedVersion = localStorage.getItem('termsAcceptedVersion');
    
    if (termsAccepted && acceptedVersion === TERMS_VERSION) {
      setIsAccepted(true);
    }
  }, []);

  // Container Variants for Staggered Animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  // Item Variants for Individual Section Animations
  const itemVariants = {
    hidden: { 
      opacity: 0, 
      y: 50 
    },
    visible: {
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  // Detailed Sections
  const sections = [
    {
      title: "User Rights & Responsibilities",
      icon: FaUserShield,
      description: "Users must maintain account confidentiality, provide accurate information, and use Streamify services responsibly and ethically."
    },
    {
      title: "Content Usage Policy",
      icon: FaLock,
      description: "All content on Streamify is for personal, non-commercial use. Unauthorized distribution, reproduction, or streaming is strictly prohibited."
    },
    {
      title: "Privacy & Data Protection",
      icon: FaShieldAlt,
      description: "We collect and process personal data in compliance with our Privacy Policy. Users have the right to access, correct, and delete their personal information."
    },
    {
      title: "Intellectual Property",
      icon: FaBalanceScale,
      description: "All content, design, and intellectual property on Streamify are owned by us. Users may not reproduce or distribute our content without explicit permission."
    }
  ];

  // Handle Acceptance
  const handleAcceptTerms = () => {
    // Prevent multiple submissions
    if (isAccepted) return;

    // Validate any additional terms if needed
    const hasAgreedToAllTerms = true; // Add your custom validation logic

    if (hasAgreedToAllTerms) {
      // Mark terms as accepted in local storage
      localStorage.setItem('termsAccepted', 'true');
      localStorage.setItem('termsAcceptedVersion', TERMS_VERSION);
      
      // Show success toast
      toast.success('Terms Accepted Successfully!', {
        icon: <FaCheck className="text-green-500 text-xl" />,
        style: {
          background: '#2C3E50',
          color: '#ECF0F1',
          border: '2px solid #b8962e',
          borderRadius: '12px',
          padding: '16px',
        },
        duration: 2000,
      });

      // Set accepted state
      setIsAccepted(true);

      // Redirect after toast duration
      setTimeout(() => {
        navigate('/home');
      }, 2000);
    } else {
      // Show error toast if terms are not fully agreed
      toast.error('Please agree to all terms', {
        icon: <FaExclamationTriangle className="text-red-500 text-xl" />,
        style: {
          background: '#2C3E50',
          color: '#ECF0F1',
          border: '2px solid #ff4444',
          borderRadius: '12px',
          padding: '16px',
        },
      });
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="relative bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6 overflow-hidden"
    >
      {/* Toast Notification */}
      <Toaster 
        position="top-right"
        reverseOrder={false}
      />

      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>

      {/* Animated Header */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 100, 
          damping: 10 
        }}
        className="text-center mb-12 relative z-10 mt-14"
      >
        <motion.h1 
          className="text-5xl font-bold mb-4 text-gold-500 tracking-wider"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ 
            type: "spring", 
            stiffness: 300, 
            damping: 10 
          }}
        >
          Terms and Services
        </motion.h1>
        <motion.p 
          className="max-w-2xl mx-auto text-gold-300 text-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Welcome to <span className="font-bold text-gold-500">Streamify</span>. Please read our terms carefully.
        </motion.p>
      </motion.div>

      {/* Animated Sections */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-4xl mx-auto space-y-8 relative z-10"
      >
        {sections.map((section, index) => (
          <motion.div 
            key={index}
            variants={itemVariants}
            whileHover={{ 
              scale: 1.03,
              transition: { duration: 0.2 }
            }}
            className="
              bg-zinc-800/50 
              border border-gold-500/20 
              rounded-xl 
              p-6 
              shadow-lg 
              hover:shadow-2xl 
               transition-all 
              duration-300
            "
          >
            <div className="flex items-center mb-4">
              <section.icon className="text-gold-500 text-3xl mr-3" />
              <h2 className="text-xl font-semibold text-gold-500">{section.title}</h2>
            </div>
            <p className="text-gray-300">{section.description}</p>
          </motion.div>
        ))}
        {/* Legal Disclaimer */}
        <motion.div
          variants={containerVariants}
          className="bg-red-500/10 border border-red-500/30 rounded-xl p-6 mt-8"
        >
          <div className="flex items-center mb-4">
            <FaGavel className="text-4xl text-red-500 mr-4" />
            <h2 className="text-2xl font-semibold text-red-400">
              Legal Disclaimer
            </h2>
          </div>
          <p className="text-gold-300 italic">
            These terms are subject to change. Continued use of Streamify constitutes acceptance of any modifications.
          </p>
        </motion.div>
      </motion.div>

      {/* Footer Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 100, 
          delay: 0.5 
        }}
        className="text-center mt-12 max-w-2xl mx-auto relative z-10"
      >
        <motion.button
          onClick={handleAcceptTerms}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isAccepted}
          className={`
            bg-gold-500 
            text-black 
            px-8 py-3 
            rounded-full 
            font-bold 
            text-lg
            hover:bg-gold-600
            transition-all
            duration-300
            shadow-lg
            hover:shadow-xl
            ${isAccepted ? 'opacity-50 cursor-not-allowed' : ''}
          `}
        >
          {isAccepted ? 'Accepted' : 'I Understand'}
        </motion.button>
        <p className="text-gold-300 mt-4 text-sm">
          By clicking "I Understand", you agree to our Terms of Service
        </p>
      </motion.div>
    </motion.div>
  );
};

export default TermsAndServices;