import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  FaServer, 
  FaStar, 
  FaGlobe, 
  FaCalendar, 
  FaFilm,
  FaRegBookmark,
} from 'react-icons/fa';
import { CgNametag } from "react-icons/cg";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { TbLanguage } from "react-icons/tb";
import { TiTags } from "react-icons/ti";

import { 
  fetchAnimeDetails, 
  fetchEpisodeServers 
} from '../services/Consumet';

const AnimeWatchPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State Management
  const [animeDetails, setAnimeDetails] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch Anime Data
  const fetchAnimeData = useCallback(async () => {
    try {
      setLoading(true);
      const details = await fetchAnimeDetails(id);

      if (!details) {
        throw new Error('Anime details not found');
      }

      setAnimeDetails(details);
      setEpisodes(details.episodes || []);
      
      // Auto-select first episode if available
      if (details.episodes && details.episodes.length > 0) {
        await handleEpisodeSelection(details.episodes[0]);
      }

    } catch (err) {
      console.error('Anime Fetch Error:', err);
      setError(err.message || 'Failed to fetch anime details');
    } finally {
      setLoading(false);
    }
  }, [id]);

  // Handle Episode Selection
  const handleEpisodeSelection = useCallback(async (episode) => {
    try {
      const episodeId = `${episode.id}`;
      const episodeServers = await fetchEpisodeServers(episodeId);
      
      if (episodeServers && episodeServers.length > 0) {
        setSelectedEpisode(episode);
        setServers(episodeServers);
        setSelectedServer(episodeServers[0]);
      } else {
        throw new Error('No streaming servers found');
      }
    } catch (err) {
      console.error('Episode Selection Error:', err);
      setError(err.message || 'Failed to select episode');
    }
  }, []);

  // Toggle Favorite
  const handleFavoriteToggle = () => {
    setIsFavorite(!isFavorite);
  };

  // Initial Data Fetch
  useEffect(() => {
    if (id) {
      fetchAnimeData();
    }
  }, [id, fetchAnimeData]);

  // Loading State
  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-pulse text-gold-500">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-16 w-16" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.597-2.1A1 1 0 009 10.2v3.6a1 1 0 001.155.832l3.597-2.1a1 1 0 000-1.732z" />
          </svg>
        </div>
      </div>
    );
  }

  // Error State
  if (error) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-gold-500 text-3xl mb-4">Oops! Something went wrong</h2>
          <p className="text-gold-400 mb-4">{error}</p>
          <div className="space-x-4">
            <button 
              onClick={() => window.location.reload()}
              className="bg-gold-500 text-black px-4 py-2 rounded hover:bg-gold-600"
            >
              Retry
            </button>
            <button 
              onClick={() => navigate('/anime')}
              className="bg-gray-800 text-gold-500 px-4 py-2 rounded hover:bg-gray-700"
            >
              Go Home
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-8 mt-10">
          {/* Top Section: Player and Servers/Episodes */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Video Player */}
            <div className="lg:col-span-3 rounded-2xl overflow-hidden shadow-2xl">
              {selectedServer ? (
                <div className="aspect-video w-full">
                  <iframe
                    src={selectedServer.url}
                    title={`Episode ${selectedEpisode?.number}`}
                    className="w-full h-full rounded-lg shadow-md shadow-gray-800"
                    allowFullScreen
                    frameBorder="0"
                  />
                </div>
              ) : (
                <div className="h-full flex items-center justify-center text-gold-500">
                  No server selected
                </div>
              )}
            </div>

             {/* Servers and Episodes Sidebar */}
             <div className="lg:col-span-1 space-y-4">
              {/* Server Selection */}
              <div className="bg-gray-900 rounded-2xl p-4">
                <h3 className="text-xl font-semibold mb-4 flex items-center text-gold-500">
                  <FaServer className="mr-2" />
                  Streaming Servers
                </h3>
                <div className="flex flex-wrap grid gap-2">
  {servers.map((server, index) => (
    <button
      key={index}
      onClick={() => setSelectedServer(server)}
      className={`
        px-4 py-2 
        rounded-xl 
        text-sm 
        font-semibold 
        transition-all 
        duration-300 
        border 
        transform 
        hover:scale-105 
        focus:outline-none 
        group
        ${selectedServer?.name === server.name 
          ? 'bg-gold-500 text-black border-gold-600 shadow-2xl' 
          : 'bg-gray-900 text-gold-400 border-gray-700 hover:bg-gray-800 hover:border-gold-500 hover:text-gold-300'}
        
        relative 
        overflow-hidden
        before:absolute 
        before:inset-0 
        before:bg-gradient-to-br 
        before:from-transparent 
        before:to-white/10 
        before:opacity-0 
        hover:before:opacity-20
        active:scale-95
      `}
    >
      <span className="relative z-10">
        {server.name}
      </span>
    </button>
  ))}
</div>
              </div>

              {/* Episode List */}
              <div className="bg-gray-900 rounded-2xl p-4">
                <h3 className="text-xl font-semibold mb-4 flex items-center text-gold-500">
                  Episodes
                </h3>
                <div className="grid grid-cols-3 gap-2 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gold-500 scrollbar-track-black">
                  {episodes.map((episode) => (
                    <button
                      key={episode.id}
                      onClick={() => handleEpisodeSelection(episode)}
                      className={`
                        text-xs px-2 py-1 rounded-lg transition-colors
                        ${selectedEpisode?.id === episode.id 
                          ? 'bg-gold-500 text-black' 
                          : 'bg-gray-800 text-gold-500 hover:bg-gold-600'}
                      `}
                    >
                      {episode.number}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          

          {/* Bottom Section: Info and Recommendations */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Anime Information */}
            <div className="lg:col-span-3 bg-gradient-to-br from-gray-900 to-black rounded-2xl p-8 shadow-2xl border border-gold-500/20">
              <div className="flex items-start space-x-6">
                <img 
                  src={animeDetails?.image} 
                  alt={`${animeDetails?.title?.romaji} Poster`} 
                  className="w-48 h-72 rounded-xl shadow-lg object-cover"
                />
                <div className="flex-1">
                  <h2 className="text-3xl font-bold text-gold-500 mb-4">
                    {animeDetails?.title?.romaji}
                  </h2>
                  <div className="flex items-center space-x-4">
                <div className="flex mb-2 items-center space-x-2">
                  <FaStar className="h-6 w-6 text-yellow-400" />
                  <span className="text-xl">{animeDetails.rating || 'N/A'}</span>
                </div>
                <div className="h-6 border-l border-gray-600 mx-2"></div>
                <span className="text-xl">{animeDetails.type}</span>
                <div className="h-6 border-l border-gray-600 mx-2"></div>
                <span className="text-xl">{animeDetails.duration} min</span>
                <div className="h-6 border-l border-gray-600 mx-2"></div>
                <span className="text-xl">{animeDetails.status}</span>
              </div>

                  <p className="text-gray-300 mb-6 leading-relaxed">
                    {animeDetails?.description}
                  </p>
                  
                  <div className="grid grid-cols-2 gap-4 text-sm">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <CgNametag className="text-gold-500" />
                        <span>Other Titles: {animeDetails?.title.native}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <FaGlobe className="text-gold-500" />
                        <span>Country: {animeDetails?.countryOfOrigin}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <MdOutlineAccessTimeFilled className="text-gold-500" />
                        <span>Aired: {animeDetails?.releaseDate}</span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <FaCalendar className="text-gold-500" />
                        <span>Season: {animeDetails?.season}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <TbLanguage className="text-gold-500" />
                        <span>Type: {animeDetails?.subOrDub}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <TiTags className="text-gold-500" />
                        <span>Genres: {animeDetails?.genres.join(', ')}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Recommendations Sidebar */}
            <div className="lg:col-span-1 bg-gray-900 rounded-2xl p-4 shadow-xl">
  <h3 className="text-lg font-semibold text-gold-500 mb-4">
    Recommended
  </h3>
  <div className="space-y-3 max-h-[350px] overflow-y-auto scrollbar-thin scrollbar-thumb-gold-500 scrollbar-track-black">
    {animeDetails?.recommendations?.slice(0, 8).map((rec) => (
      <Link 
        key={rec.id} 
        to={`/watch/anime/${rec.id}`} 
        className="block bg-black/30 p-2 rounded-lg hover:bg-gold-500/20 transition-colors"
      >
        <div className="flex items-center">
          <img 
            src={rec.image} 
            alt={rec.title.romaji} 
            className="w-12 h-16 rounded-lg mr-3 object-cover"
          />
          <div className="flex-1 overflow-hidden">
            <h4 className="text-gold-400 text-sm font-semibold truncate">
              {rec.title.romaji}
            </h4>
            <div className="flex items-center space-x-1 overflow-hidden	">
              <p className="text-xs text-gray-400 flex-grow">
                {rec.type} · {rec.episodes} Eps
              </p>
              <div className="flex items-center">
                <FaStar className="text-yellow-500 mr-1 text-xs" />
                <span className="text-xs text-gray-300">
                  {rec.rating ? rec.rating.toFixed(1) : 'N/A'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    ))}
  </div>
</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimeWatchPage;