import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, useViewportScroll } from 'framer-motion';
import toast, { Toaster } from 'react-hot-toast';
import { 
  FaUser, 
  FaCog, 
  FaSignOutAlt, 
  FaChartBar, 
  FaClock, 
  FaBell,
  FaEdit,
  FaShieldAlt,
  FaKey,
  FaImage,
  FaTrash,
  FaHeart
} from 'react-icons/fa';
import { MdFavorite } from 'react-icons/md';
import { getFavorites, removeFromFavorites } from '../services/favorites';

// Custom Toast Styles
const toastOptions = {
  style: {
    background: '#2C3E50',
    color: '#ECF0F1',
    border: '2px solid #b8962e',
    borderRadius: '12px',
    padding: '16px',
  },
  success: {
    style: {
      background: '#2C3E50',
      color: '#2ECC71',
      border: '2px solid #2ECC71',
    },
    icon: '✅'
  },
  error: {
    style: {
      background: '#2C3E50',
      color: '#E74C3C',
      border: '2px solid #E74C3C',
    },
    icon: '❌'
  }
};
const BackgroundEffect = () => {
  const { scrollYProgress } = useViewportScroll();
  
  return (
    <motion.div 
      className="fixed inset-0 z-0 overflow-hidden pointer-events-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {[...Array(30)].map((_, i) => (
        <motion.div 
          key={i}
          className="absolute bg-gold-500/10 rounded-full"
          initial={{ 
            scale: 0, 
            top: `${Math.random() * 100}%`, 
            left: `${Math.random() * 100}%` 
          }}
          animate={{ 
            scale: [0, 2, 0],
            rotate: 360,
            x: scrollYProgress.get() * 100,
            y: scrollYProgress.get() * -100
          }}
          transition={{
            duration: Math.random() * 10 + 5,
            repeat: Infinity,
            delay: Math.random() * 2
          }}
          style={{
            width: `${Math.random() * 200 + 50}px`,
            height: `${Math.random() * 200 + 50}px`,
            opacity: 0.5
          }}
        />
      ))}
    </motion.div>
  );
};

const FavoritesSection = ({ favorites, onRemoveFavorite }) => {
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    const mediaType = item.media_type || (item.title ? 'movie' : 'tv');
    navigate(`/watch/${mediaType}/${item.id}`);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
    >
      {favorites.map((item) => (
        <motion.div 
          key={item.id}
          className="relative group cursor-pointer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleItemClick(item)}
        >
          <div className="relative pt-[150%] overflow-hidden rounded-xl shadow-lg">
            <img 
              src={item.poster} 
              alt={item.title} 
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveFavorite(item.id);
                }}
                className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
              >
                <FaTrash />
              </button>
            </div>
          </div>
          <div className="mt-2 text-center">
            <h3 className="text-sm font-semibold text-gold-400 truncate">
              {item.title}
            </h3>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [activeSection, setActiveSection] = useState('overview');
  const [favorites, setFavorites] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const navigate = useNavigate();

  // Fetch and Load User Data
  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        setUserInfo(parsedUserInfo);
        setEditedProfile(parsedUserInfo);
      } catch (error) {
        console.error('Error parsing user info:', error);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  // Fetch Favorites
  useEffect(() => {
    const favs = getFavorites();
    setFavorites(favs);
  }, []);

  // Remove Favorite
  const handleRemoveFavorite = (id) => {
    removeFromFavorites(id);
    setFavorites(prev => prev.filter(item => item.id !== id));
  };

  // Profile Edit Handlers
  const handleEditToggle = () => {
    if (editMode) {
      // Save changes logic
      localStorage.setItem('userInfo', JSON.stringify({
        ...userInfo,
        ...editedProfile
      }));
      setUserInfo(prev => ({...prev, ...editedProfile}));
    }
    setEditMode(!editMode);
  };

  const handleProfileImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedProfile(prev => ({
          ...prev,
          profilePicture: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Logout Handler
  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    sessionStorage.removeItem('userInfo');
    navigate('/login');
  };

  // Render Sections
  const renderSection = useCallback(() => {
    switch (activeSection) {
      case 'overview':
        return (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="grid md:grid-cols-3 gap-6"
          >
            <div className="bg-zinc-800 rounded-2xl p-6 shadow-2xl border border-gold-500/20">
              <div className="flex items-center mb-4 space-x-3">
                <MdFavorite className="text-gold-500 text-2xl" />
                <h2 className="text-2xl font-bold text-gold-500">Favorites</h2>
              </div>
              <div className="text-4xl font-bold text-gold-400">
                {favorites.length}
              </div>
              <p className="text-gray-400">Total Favorites</p>
            </div>

            <div className="bg-zinc-800 rounded-2xl p-6 shadow-2xl border border-gold-500/20">
              <div className="flex items-center mb-4 space-x-3">
                <FaClock className="text-gold-500 text-2xl" />
                <h2 className="text-2xl font-bold text -gold-500">Watched Time</h2>
              </div>
              <div className="text-4xl font-bold text-gold-400">
                42h 15m
              </div>
              <p className="text-gray-400">This Month</p>
            </div>

            <div className="bg-zinc-800 rounded-2xl p-6 shadow-2xl border border-gold-500/20">
              <div className="flex items-center mb-4 space-x-3">
                <FaShieldAlt className="text-gold-500 text-2xl" />
                <h2 className="text-2xl font-bold text-gold-500">Security</h2>
              </div>
              <div className="text-lg font-semibold text-gold-400">
                {userInfo?.recoveryCode}
              </div>
              <p className="text-gray-400">Recovery Code</p>
            </div>
          </motion.div>
        );

      case 'favorites':
        return (
          <FavoritesSection 
            favorites={favorites} 
            onRemoveFavorite={handleRemoveFavorite} 
          />
        );

      case 'settings':
        return (
          <div className="bg-zinc-800 rounded-2xl p-6 shadow-2xl border border-gold-500/20 max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold text-gold-500 mb-4">Account Settings</h2>
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8">
              {/* Profile Image */}
              <div className="relative">
                <div className="w-40 h-40 rounded-full border-4 border-gold-500 overflow-hidden">
                  <img 
                    src={editedProfile.profilePicture || 'default-avatar.png'}
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                  {editMode && (
                    <label className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center cursor-pointer">
                      <FaImage className="text-white text-2xl" />
                      <input 
                        type="file" 
                        className="hidden" 
                        accept="image/*"
                        onChange={handleProfileImageUpload}
                      />
                    </label>
                  )}
                </div>
              </div>

              {/* Profile Details */}
              <div className="flex-grow space-y-4">
                <div>
                  <label className="block text-gold-400 mb-2">Username</label>
                  {editMode ? (
                    <input 
                      type="text"
                      value={editedProfile.username}
                      onChange={(e) => setEditedProfile(prev => ({
                        ...prev, 
                        username: e.target.value
                      }))}
                      className="w-full p-2 bg-black text-white rounded"
                    />
                  ) : (
                    <p className="text-white">{userInfo?.username}</p>
                  )}
                </div>
                <div>
                  <label className="block text-gold-400 mb-2">Email</label>
                  {editMode ? (
                    <input 
                      type="email"
                      value={editedProfile.email}
                      onChange={(e) => setEditedProfile(prev => ({
                        ...prev, 
                        email: e.target.value
                      }))}
                      className="w-full p-2 bg-black text-white rounded"
                    />
                  ) : (
                    <p className="text-white">{userInfo?.email}</p>
                  )}
                </div>
                <button 
                  onClick={handleEditToggle}
                  className="bg-gold-500 text-black px-4 py-2 rounded hover:bg-gold-600 transition"
                >
                  {editMode ? 'Save Changes' : 'Edit Profile'}
                </button>
              </div>
            </div>
          </div>
        );

      case 'activity':
        return (
          <div className="bg-zinc-800 rounded-2xl p-6 shadow-2xl border border-gold-500/20">
            <h2 className="text-2xl font-bold text-gold-500 mb-4">Recent Activity</h2>
            <ul className="space-y-2">
              <li className="text-gray-300">Watched "Inception" - 2h 28m ago</li>
              <li className="text-gray-300">Added "The Matrix" to favorites - 1d ago</li>
              <li className="text-gray-300">Updated profile picture - 3d ago</li>
           
            </ul>
          </div>
        );

      default:
        return null;
    }
  }, [activeSection, userInfo, favorites, editMode, editedProfile]);

  return (
    <div className="min-h-screen bg-black relative">
      <BackgroundEffect />
      <div className="max-w-4xl mx-auto relative z-10 p-8 ">
        <motion.div 
          className="bg-black/60 backdrop-blur-xl rounded-2xl p-6 shadow-2xl border border-gold-500/20 mt-10"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ type: "spring", stiffness: 120 }}
        >
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold text-gold-500">Profile</h1>
            <button 
              onClick={handleLogout} 
              className="flex items-center text-red-500 hover:text-red-400 transition"
            >
              <FaSignOutAlt className="mr-2" /> Logout
            </button>
          </div>

          <div className="flex space-x-4 mb-6">
            <button 
              onClick={() => setActiveSection('overview')} 
              className={`flex-1 py-2 text-center rounded-lg ${activeSection === 'overview' ? 'bg-gold-500 text-black' : 'bg-gray-700 text-gray-300'}`}
            >
              Overview
            </button>
            <button 
              onClick={() => setActiveSection('favorites')} 
              className={`flex-1 py-2 text-center rounded-lg ${activeSection === 'favorites' ? 'bg-gold-500 text-black' : 'bg-gray-700 text-gray-300'}`}
            >
              Favorites
            </button>
            <button 
              onClick={() => setActiveSection('settings')} 
              className={`flex-1 py-2 text-center rounded-lg ${activeSection === 'settings' ? 'bg-gold-500 text-black' : 'bg-gray-700 text-gray-300'}`}
            >
              Settings
            </button>
            <button 
              onClick={() => setActiveSection('activity')} 
              className={`flex-1 py-2 text-center rounded-lg ${activeSection === 'activity' ? 'bg-gold-500 text-black' : 'bg-gray-700 text-gray-300'}`}
            >
              Activity
            </button>
          </div>

          <AnimatePresence>
            {renderSection()}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default Profile;