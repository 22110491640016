import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { searchContent } from '../services/search';
import { FaPlay, FaStar } from 'react-icons/fa';
import Filter from '../components/Filter';
import { motion } from 'framer-motion';
const SearchPage = () => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filters, setFilters] = useState({ 
    genre: '', 
    year: '', 
    type: '',
    rating: ''
  });
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('query');

  // Utility function to safely format rating
  const formatRating = (rating) => {
    if (typeof rating === 'number') {
      return rating.toFixed(1);
    }
    const numRating = parseFloat(rating);
    if (!isNaN(numRating)) {
      return numRating.toFixed(1);
    }
    return 'N/A';
  };

  // Utility function to get poster image
  const getPosterImage = (item) => {
    // Prioritize different poster sources
    if (item.source === 'tmdb') {
      // For TMDB, construct full poster path
      return item.poster_path 
        ? `https://image.tmdb.org/t/p/w500${item.poster_path}`
        : 'https://via.placeholder.com/300x450';
    } else if (item.source === 'consumet') {
      // For Consumet, use image directly
      return item.image || 'https://via.placeholder.com/300x450';
    }
    
    // Fallback placeholder
    return 'https://via.placeholder.com/300x450';
  };

  // Fetch search results
  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await searchContent(searchQuery);
        
        // Combine and normalize results
        const combinedResults = [
          ...data.movies.map(item => ({
            ...item,
            source: 'tmdb',
            media_type: 'movie'
          })),
          ...data.tvShows.map(item => ({
            ...item,
            source: 'tmdb',
            media_type: 'tv'
          })),
          ...data.anime.map(item => ({
            ...item,
            source: 'consumet',
            media_type: 'anime',
            poster_path: item.image, // Normalize poster path
            vote_average: item.rating/10, // Normalize rating
            title: item.title // Normalize title
          }))
        ];

        setResults(combinedResults);
        setFilteredResults(combinedResults);
      } catch (error) {
        console.error('Search error:', error);
      }
    };

    if (searchQuery) {
      fetchResults();
    }
  }, [searchQuery]);

  // Filter change handler (previous implementation remains the same)
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    let filtered = results;

    // Genre filter
    if (newFilters.genre) {
      filtered = filtered.filter(item => {
        // Handle different sources' genre formats
        if (item.source === 'tmdb') {
          return item.genre_ids?.includes(Number(newFilters.genre));
        }
        if (item.source === 'consumet') {
          return item.genres?.some(genre => 
            genre.toLowerCase().includes(newFilters.genre.toLowerCase())
          );
        }
        return true;
      });
    }

    // Year filter
    if (newFilters.year) {
      filtered = filtered.filter(item => {
        const year = item.release_date 
          || item.first_air_date 
          || item.releaseDate;
        return year?.startsWith(newFilters.year);
      });
    }

    // Type filter
    if (newFilters.type) {
      filtered = filtered.filter(item => 
        item.media_type === newFilters.type
      );
    }

    // Rating filter
    if (newFilters.rating) {
      filtered = filtered.filter(item => {
        const rating = parseFloat(item.vote_average || item.rating || 0);
        return rating >= Number(newFilters.rating);
      });
    }

    setFilteredResults(filtered);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen p-6">
      {/* Animated Background Particles */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        className="absolute inset-0 z-0 pointer-events-none"
      >
        {[...Array(50)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ 
              top: `${Math.random() * 100}%`, 
              left: `${Math.random() * 100}%`,
              scale: 0
            }}
            animate={{ 
              scale: [0, 2, 0],
              rotate: 360
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
            className="absolute bg-gold-500/10 rounded-full"
            style={{
              width: `${Math.random() * 200 + 50}px`,
              height: `${Math.random() * 200 + 50}px`
            }}
          />
        ))}
      </motion.div>
      <div className='mb-8 mt-14'>
        <h1 className="text-3xl font-bold text-gold-500 mb-4">
          Search Results for "{searchQuery}"
        </h1>
      </div>
      
      <Filter onFilterChange={handleFilterChange} />
      
      <div className="grid grid-cols-3 mt-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
        {filteredResults.length > 0 ? (
          filteredResults.map(item => (
            <Link 
              to={`/watch/${item.media_type}/${item.id}`} 
              key={`${item.source}-${item.id}`} 
              className="group relative"
            >
              <div className="
                relative overflow-hidden rounded-xl shadow-xl 
                transition-all duration-300 
                border border-transparent
                group-hover:border-gold-500/50 
                group-hover:scale-105 
                group-hover:shadow-2xl 
                bg-black/5"
              >
                {/* Source Badge */}
                {item.source === 'consumet' && (
                  <div className="absolute top-2 left-2 z-10 
                                  bg-purple-600 text-white 
                                  px-2 py-1 rounded-full 
                                  text-xs font-bold">
                    Anime
                  </div>
                )}

                {/* Poster Image */}
                <div className="relative pt-[150%] overflow-hidden"> 
                  <img 
                    src={getPosterImage(item)}
                    alt={item.title || item.name}
                    className="absolute inset-0 w-full h-full object-cover 
                               opacity-90 group-hover:opacity-100 
                               transition-opacity duration-300 
                               transform group-hover:scale-110"
                  />
                  
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                  transition-all duration-300 flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <FaPlay className="text-gold-500 text-4xl 
                                        transform hover:scale-110 
                                        transition-transform" />
                    </div>
                  </div>
                  
                  {/* Rating Overlay */}
                  <div className="absolute top-2 right-2 
                                  bg-black/60 rounded-full 
                                  px-2 py-1 flex items-center">
                    <FaStar className="text-gold-500 mr-1" />
                    <span className="text-xs text-gold-400 font-bold">
                      {formatRating(item.vote_average || item.rating)}
                    </span>
                  </div>
                </div>

                {/* Title Overlay */}
                <div className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to-transparent 
                                p-2"> <h3 className="text-xs font-semibold text-gold-400 truncate">
                    {item.title || item.name}
                  </h3>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="col-span-full text-center text-gray-400">
            No results found.
          </p>
        )}
      </div>
    </div>
  );
};

export default SearchPage;