import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';

export const Footer = () => {
  // Animation variants (keep the previous animation setup)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.footer 
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={containerVariants}
      className="bg-gradient-to-b from-black/40 to-[#0a0a0a] border-t border-gold-950/20 py-16 overflow-hidden"
    >
      <div className="container mx-auto px-6 max-w-6xl">
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-12"
          variants={containerVariants}
        >
          {/* Brand Section */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h3 className="text-3xl font-bold text-gold-500 tracking-wider mb-6 
              bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-700
              hover:from-gold-600 hover:to-gold-800 transition-all duration-500">
              Streamify
            </h3>
            <p className="text-gold-300 text-sm leading-relaxed mb-6 
              transition-all duration-500 hover:text-gold-200">
              Discover, stream, and immerse yourself in a world of endless entertainment. From blockbuster movies to hidden anime gems, your ultimate streaming companion awaits.
            </p>
            
            {/* Social Icons */}
            <div className="flex space-x-5">
              {[
                { Icon: FaTwitter, link: '#twitter' },
                { Icon: FaLinkedin, link: '#linkedin' },
                { Icon: FaGithub, link: 'github.com/CryptoDogNft' },
                { Icon: FaEnvelope, link: 'mailto:support@streamify.com' }
              ].map(({ Icon, link }, index) => (
                <motion.a 
                  key={link}
                  href={link}
                  variants={itemVariants}
                  whileHover={{ 
                    scale: 1.2,
                    rotate: [0, -10, 10, 0],
                    transition: { duration: 0.3 }
                  }}
                  whileTap={{ scale: 0.9 }}
                  className="text-gold-400 hover:text-gold-500 transition-all duration-300"
                >
                  <Icon className="text-2xl" />
                </motion.a>
              ))}
            </div>
          </motion.div>
          
          {/* Quick Links */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
          >
            <h4 className="text-xl font-semibold text-gold-500 mb-6 border-b border-gold-950 pb-3">
              Quick Links
            </h4>
            <div className="grid grid-cols-2 gap-4">
              {[
                [
                  { name: 'Home', path: '/home' },
                  { name: 'Movies', path: '/movies' },
                  { name: 'TV Shows', path: '/tv' },
                  {name: 'Anime', path: '/anime'}
                ],
                [
                  { name: 'Filter', path: '/filter' },
                  { name: 'Profile', path: '/profile' },
                  { name: 'About Us', path: '/about' }
                ]
              ].map((linkGroup, groupIndex) => (
                <ul key={groupIndex} className="space-y-3">
                  {linkGroup.map((link) => (
                    <motion.li 
                      key={link.name}
                      variants={itemVariants}
                      whileHover={{ 
                        x: 10,
                        transition: { type: "spring", stiffness: 300 }
                      }}
                    >
                      <Link 
                        to={link.path}
                        className="text-gold-300 hover:text-gold-500 transition-colors flex items-center group"
                      >
                        <span className="mr-2 text-gold-600 group-hover:translate-x-1 transition-transform">→</span>
                        {link.name}
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              ))}
            </div>
          </motion.div>
          
          {/* Legal & Contact */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
          >
            <h4 className="text-xl font-semibold text-gold-500 mb-6 border-b border-gold-950 pb-3">
              Legal & Contact
            </h4>
            <ul className="space-y-3">
              {[
                { name: 'Privacy Policy', path: '/privacy' },
                { name: 'Terms of Service', path: '/terms' },
                { name: 'DMCA', path: '/dcma' },
                { name: 'Contact Support', path: '/contact' }
              ].map((link) => (
                <motion.li 
                  key={link.name}
                  variants={itemVariants}
                  whileHover={{ 
                    x: 10,
                    transition: { type: "spring", stiffness: 300 }
                  }}
                >
                  <Link 
                    to={link.path}
                    className="text-gold-300 hover:text-gold-500 transition-colors flex items-center group"
                  >
                    <span className="mr-2 text-gold-600 group-hover:translate-x-1 transition-transform">→</span>
                    {link.name}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
        
        {/* Footer Bottom */}
        <motion.div 
          className="mt-12 pt-8 border-t border-gold-950/20 text-center"
          variants={itemVariants}
        >
          <p className="text-gold-400 text-sm mb-4">
            © {new Date().getFullYear()} Streamify. All Rights Reserved.
          </p>
          <div className="flex justify-center space-x-4">
            {['Secure Streaming', 'Private Experience'].map((badge) => (
              <motion.span 
                key={badge}
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ 
                  opacity: 1, 
                  scale: 1,
                  transition: { 
                    type: "spring", 
                    stiffness: 300 
                  }
                }}
                whileHover={{ scale: 1.1 }}
                className="bg-gold-600/20 text-gold-400 px-3 py-1 rounded-full text-xs border border-gold-500/30 cursor-default"
              > {badge}
              </motion.span>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default Footer;