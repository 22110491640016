import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  FaStar, 
  FaClock, 
  FaPlay,
  FaChevronRight,
  FaChevronLeft,
  FaTheaterMasks,
} from 'react-icons/fa';
import { fetchMovieDetails, fetchTVShowDetails } from '../services/api';

const PremiumCarousel = ({ items = [], genres = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [details, setDetails] = useState({
    duration: 'N/A',
    genreNames: 'N/A',
    loading: true
  });
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  // Advanced Details Fetching
  useEffect(() => {
    const fetchAdvancedDetails = async () => {
      const currentItem = items[currentIndex];
      if (currentItem) {
        try {
          let mediaDetails;
          // Determine media type more flexibly
          const isMovie = currentItem.title || currentItem.media_type === 'movie';
          
          if (isMovie) {
            mediaDetails = await fetchMovieDetails(currentItem.id);
          } else {
            mediaDetails = await fetchTVShowDetails(currentItem.id);
          }

          // Enhanced Genre Mapping
          const genreNamesList = mediaDetails.genres
            ? mediaDetails.genres
                .map((genre) => {
                  // First, try to find in provided genres array
                  const matchedGenre = genres.find((g) => g.id === genre.id);
                  return matchedGenre ? matchedGenre.name : genre.name;
                })
                .join(', ')
            : 'N/A';

          // Comprehensive details extraction
          const duration = mediaDetails.runtime || 
            (mediaDetails.episode_run_time && mediaDetails.episode_run_time.length > 0 
              ? mediaDetails.episode_run_time[0] 
              : 'N/A');
          
          setDetails({
            duration,
            genreNames: genreNamesList,
            loading: false
          });
        } catch (error) {
          console.error('Enhanced Details Fetch Error:', error);
          setDetails(prev => ({ 
            ...prev, 
            genreNames: 'N/A', 
            loading: false 
          }));
        }
      }
    };

    fetchAdvancedDetails();
  }, [currentIndex, items, genres]);

  // Advanced Slide Navigation
  const navigateSlide = (direction) => {
    const totalItems = items.length;
    setCurrentIndex((prev) => {
      if (direction === 'next') {
        return (prev + 1) % totalItems;
      }
      return prev === 0 ? totalItems - 1 : prev - 1;
    });
  };

  // Automatic Slide Progression
  useEffect(() => {
    const slideInterval = setInterval(() => {
      navigateSlide('next');
    }, 7000); // 7 seconds per slide

    return () => clearInterval(slideInterval);
  }, [items.length]);

  // Media Interaction Handler

  
  // Media Interaction Handler
  const handleMediaWatch = (item) => {
    try {
      // Log the entire item for inspection
      console.log('Full Item Details:', item);
  
      // Validate item and its properties
      if (!item) {
        console.error('No item provided');
        return;
      }
  
      // Explicitly determine media type based on the item's properties
      const mediaType = item.title ? 'movie' : 'tv';
      const mediaId = item.id;
  
      // Extensive logging
      console.log('Determined Media Type:', mediaType);
      console.log('Media ID:', mediaId);
  
      // Additional validation
      if (!mediaId) {
        console.error('Invalid media ID');
        return;
      }
  
      // Construct and log the full navigation path
      const navigationPath = `/watch/${mediaType}/${mediaId}`;
      console.log('Attempting to navigate to:', navigationPath);
  
      // Perform navigation
      navigate(navigationPath);
    } catch (error) {
      console.error('Navigation Error:', error);
    }
  };
  // Prevent render if no items
  if (!items.length || details.loading) {
    return (
      <div className="h-screen flex items-center justify-center bg-black">
        <div className="animate-pulse text-gold-500 text-4xl">
          Loading Cinematic Experience...
        </div>
      </div>
    );
  }

  // Current Item Details
  const currentItem = items[currentIndex];
  const backdropPath = currentItem.backdrop_path 
    ? `https://image.tmdb.org/t/p/original${currentItem.backdrop_path}` 
    : '';
  const title = currentItem.title || currentItem.name || 'Untitled';
  const description = currentItem.overview 
    ? `${currentItem.overview.slice(0, 150)}...` 
    : 'No description available';
  const rating = currentItem.vote_average 
    ? currentItem.vote_average.toFixed(1) 
    : 'N/A';

  return (
    <div 
      ref={carouselRef}
      className="relative w-full h-screen md:h-screen overflow-hidden rounded-xl group shadow-md shadow-stone-950"
    >
      {/* Layered Background */}
      <motion.div 
        key={backdropPath}
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ 
          duration: 1.5, 
          ease: "easeInOut" 
        }}
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${backdropPath})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.5) contrast(1.2)',
          transform: 'scale(1.05)',
        }}
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r 
        from-black/90 
        via-black/50 
        to-transparent 
        z-10" 
      />

      {/* Content Container */}
      <motion.div 
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 50, 
          delay: 0.3 
        }}
        className="relative z-20 container mx-auto h-full flex items-center px-4 sm:px-6 lg:px-12"
      >
        <div className="max-w-3xl text-white space-y-4 sm:space-y-6">
          {/* Title */}
          <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-gold-500 
            drop-shadow-2xl tracking-tight line-clamp-2">
            {title}
          </h1>

          {/* Media Details */}
          <div className="flex flex-wrap items-center space-x-2 sm:space-x-4 w-full">
            {/* Quality Badge */}
            <span className="bg-gold-600 text-black px-2 py-1 sm:px-3 sm:py-1 rounded-full text-xs sm:text-sm font-bold">
              HD
            </span>

            {/* Duration */}
            {details.duration !== 'N/A' && (
              <div className="flex items-center bg-white/20 px-2 py-1 sm:px-3 sm:py-1 rounded-full">
                <FaClock className="mr-1 sm:mr-2 text-gold-500 text-xs sm:text-base" />
                <span className="text-xs sm:text-sm">{details.duration} min</span>
              </div>
            )}

            {/* Rating */}
            <div className="flex items-center bg-white/20 px-2 py-1 sm:px-3 sm:py-1 rounded-full">
              <FaStar className="mr-1 sm:mr-2 text-gold-500 text-xs sm:text-base" />
              <span className="text-xs sm:text-sm"> {rating}</span>
            </div>

            {/* Genres */} 
            {details.genreNames !== 'N/A' && (
              <div className="flex items-center bg-white/20 px-2 py-1 sm:px-3 sm:py-1 rounded-full">
                <FaTheaterMasks className="mr-1 sm:mr-2 text-gold-500 text-xs sm:text-base" />
                <span className="text-xs sm:text-sm">{details.genreNames}</span>
              </div>
            )}
          </div>

          {/* Description */}
          <p className="text-sm sm:text-base text-gray-400 leading-relaxed line-clamp-3">
            {description}
          </p>

          {/* Action Buttons */}
          <div className="flex space-x-4">
            <button 
              onClick={() => {
                console.log('Watch Now clicked for:', currentItem);
                handleMediaWatch(currentItem);
              }}
              className="
                flex items-center 
                bg-gold-500 
                text-black 
                px-4 py-2 sm:px-8 sm:py-3 
                rounded-full 
                font-bold 
                text-sm sm:text-lg
                hover:bg-gold-400
                transition-all
                group
              "
            >
              <FaPlay className="mr-2 sm:mr-3 group-hover:scale-125 transition-transform" />
              Watch Now
            </button>
          </div>
        </div>
      </motion.div>

      {/* Navigation Arrows - Responsive Positioning */}
      <div className="absolute top-1/2 left-2 sm:left-4 z-30 transform -translate-y-1/2">
        <button 
          onClick={() => navigateSlide('prev')} 
          className="bg-black/50 text-gold-500 rounded-full p-2 sm:p-3 hover:bg-gold-500 hover:text-black transition-all"
        >
          <FaChevronLeft className="text-sm sm:text-base" />
        </button>
      </div>
      <div className="absolute top-1/2 right-2 sm:right-4 z-30 transform -translate-y-1/2">
        <button 
          onClick={() => navigateSlide('next')} 
          className="bg-black/50 text-gold-500 rounded-full p-2 sm:p-3 hover:bg-gold-500 hover:text-black transition-all"
        >
          <FaChevronRight className="text-sm sm: text-base" />
        </button>
      </div>
      {/* Slide Indicators */}
<div className="absolute place-self-center bottom-10 left-0 w-full px-10 z-40">
  <div className="flex space-x-2 bg-black/40 place-self-center backdrop-blur-md rounded-full p-2">
    {items.map((_, index) => (
      <div 
        key={index}
        className={`
          h-2 rounded-full cursor-pointer transition-all duration-500
          ${index === currentIndex 
            ? 'bg-gold-500 w-24' 
            : 'bg-gray-400 hover:bg-gold-300'}
          ${index === currentIndex ? 'md:w-24' : 'md:w-12'} // Maintain larger width on medium screens
        `}
        onClick={() => setCurrentIndex(index)}
        style={{
          flex: '1', // Make indicators flexible
          minWidth: '12px', // Minimum width for touch devices
        }}
      />
    ))}
  </div>
</div>
    </div>
  );
};

export default PremiumCarousel;