import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { loadSlim } from 'tsparticles-slim';

import CustomCarousel from '../components/CustomCarousel';
import { fetchMoviesFromTMDB, fetchTVShowsFromTMDB, fetchAnimeFromTMDB } from '../services/api'; 
import { MdWhatshot } from 'react-icons/md';

import CustomTrending from '../components/CustomTrending';
import MustWatch from '../components/MustWatch';
import PopularM from '../components/PopularM';
import PopularT from '../components/PopularT';
import PopularA from '../components/PopularA';
import LatestM from '../components/LatestM';
import LatestT from '../components/LatestT';
import LatestA from '../components/LatestA';

const HomePage = () => {
  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [anime, setAnime] = useState([]);
  const [particlesLoaded, setParticlesLoaded] = useState(false);

  // Particles Configuration
  const particlesInit = async (main) => {
    // Use loadSlim for better performance
    await loadSlim(main);
    setParticlesLoaded(true);
  };

  const particlesOptions = {
    background: {
      color: { value: "transparent" },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#b8962e", // Gold color
      },
      links: {
        color: "#b8962e",
        distance: 150,
        enable: true,
        opacity: 0.3,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "bounce",
        random: false,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.2,
      },
      shape: {
        type: "circle",
      },
      size: {
        random: true,
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  // Animated background effect
  const BackgroundEffect = () => (
    <motion.div 
      className="absolute inset-0 z-0 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {[...Array(20)].map((_, i) => (
        <motion.div 
          key={i}
          className="absolute bg-gold-500/10 rounded-full"
          initial={{ 
            scale: 0, 
            top: `${Math.random() * 100}%`, 
            left: `${Math.random() * 100}%` 
          }}
          animate={{ 
            scale: [0, 2, 0],
            rotate: 360
          }}
          transition={{
            duration: Math.random() * 10 + 5,
            repeat: Infinity,
            delay: Math.random() * 2
          }}
          style={{
            width: `${Math.random() * 200 + 50}px`,
            height: `${Math.random() * 200 + 50}px`
          }}
        />
      ))}
    </motion.div>
  );

  useEffect(() => {
    const loadData = async () => {
      const moviesData = await fetchMoviesFromTMDB();
      setMovies(moviesData);
      const tvShowsData = await fetchTVShowsFromTMDB();
      setTvShows(tvShowsData);
      const animeData = await fetchAnimeFromTMDB();
      setAnime(animeData);
    };
    loadData();
  }, []);

  // Section Variants for Animations
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="relative bg-gradient-to-b from-black via-gray-900 to-black text-white min-h-screen w-full overflow-x-hidden">

      <BackgroundEffect />
   
      {/* Main Featured Carousel Section */}
      <motion.section 
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
        className="mt-8 w-full px-2 sm:px-3 md:px-1 relative z-10"
      >
        <CustomCarousel
          items={[...movies.slice(0, 4), ...tvShows.slice(0, 4), ...anime.slice(0, 4)]}
        />
      </motion.section>

      {/* Trending Section */}
      <motion.section 
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
        className="mt-12 w-full px-2 sm:px-4 md:px-6 relative z-10"
      >
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 text-yellow-500 flex items-center justify-center">
          Trending <MdWhatshot className="ml-2 text-yellow-500 text-xl sm:text-2xl md:text-3xl" />
        </h2>
        <CustomTrending />
      </motion.section>

      {/* Sections with responsive padding and animations */}
      {[
        MustWatch,
        PopularM,
        LatestM,
        PopularT,
        LatestT,
        PopularA,
        LatestA
      ].map((Component, index) => (
        <motion.section 
          key={index}
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
          className="mt-12 px-2 sm:px-4 md:px-6 relative z-10"
        >
          <Component />
        </motion.section>
      ))}
    </div>
  );
};

export default HomePage;