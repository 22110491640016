// src/services/consumet.js
import axios from 'axios';

// Base URL for Consumet API
const CONSUMET_BASE_URL = 'https://consumet-api-omega-one.vercel.app';

/**
 * Fetch Popular Anime
 * @param {number} page - Page number (default: 1)
 * @returns {Promise<Array>} List of popular anime
 */
export const fetchPopularAnime = async (startPage = 1, pageCount = 3) => {
    try {
      // Create an array of page numbers to fetch
      const pageNumbers = Array.from(
        { length: pageCount }, 
        (_, i) => startPage + i
      );
  
      // Use Promise.all to fetch pages concurrently
      const pagePromises = pageNumbers.map(async (page) => {
        try {
          const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/popular`, {
            params: { page }
          });
          return response.data.results || [];
        } catch (error) {
          console.error(`Error fetching anime for page ${page}:`, error);
          return []; // Return empty array for failed page
        }
      });
  
      // Wait for all page requests to complete
      const pageResults = await Promise.all(pagePromises);
  
      // Flatten the results and remove any empty arrays
      const flattenedResults = pageResults
        .flat()
        .filter(item => item && Object.keys(item).length > 0);
  
      // Remove duplicates based on a unique identifier (e.g., id)
      const uniqueResults = Array.from(
        new Map(flattenedResults.map(item => [item.id, item])).values()
      );
  
      return uniqueResults;
    } catch (error) {
      console.error('Error in fetchPopularAnime:', error);
      return [];
    }
  };


  export const fetchTrendingAnime = async () => {
    try {
      const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/trending`);
      return response.data.results;
    } catch (error) {
      console.error("Error fetching trending anime:", error);
      return [];
    }
  };
  
  

    
  export const fetchRecentlyAddedAnime = async () => {
    try {
      // First, fetch the recently added episodes
      const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/recent-episodes`);
      
      // Map to get unique anime IDs and fetch full details
      const uniqueAnimeIds = [...new Set(response.data.results.map(episode => episode.id))];
      
      // Fetch full details for each unique anime
      const detailedAnimePromises = uniqueAnimeIds.map(async (id) => {
        try {
          // Fetch full anime details
          const animeDetailsResponse = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/info/${id}`);
          
          // Return enhanced anime object
          return {
            ...animeDetailsResponse.data,
            rating: animeDetailsResponse.data.rating || 'N/A',
            recentEpisode: response.data.results.find(ep => ep.id === id)
          };
        } catch (detailError) {
          console.error(`Error fetching details for anime ID ${id}:`, detailError);
          return null;
        }
      });

      // Wait for all detailed anime fetches
    const detailedAnime = await Promise.all(detailedAnimePromises);
    
    // Filter out any null results and limit to first 20
    return detailedAnime
      .filter(anime => anime !== null)
      .slice(0, 20);
    
  } catch (error) {
    console.error("Error fetching recently added anime:", error);
    return [];
  }
};

  
    
  
  export const fetchAnimeDetails = async (id) => {
      try {
        const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/info/${id}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching anime details:", error);
        return null;
      }
  };
  
  export const fetchEpisodeDetails = async (episodeId) => {
    try {
      // Decode and clean the episodeId
      const cleanedEpisodeId = decodeURIComponent(episodeId)
        .replace(/[^\w-]/g, '')
        .toLowerCase();
  
      // Split the episodeId
      const parts = cleanedEpisodeId.split('-episode-');
      
      if (parts.length < 2) {
        throw new Error('Invalid episode ID format');
      }
  
      const [animeTitle, episodeNumber] = parts;
  
      // Search for the anime
      const searchResponse = await axios.get(
        `${CONSUMET_BASE_URL}/meta/anilist/${animeTitle}`
      );
  
      if (!searchResponse.data.results || searchResponse.data.results.length === 0) {
        throw new Error('Anime not found');
      }
  
      // Get the first matching anime
      const anime = searchResponse.data.results[0];
  
      // Fetch full anime details
      const animeDetailsResponse = await axios.get(
        `${CONSUMET_BASE_URL}/meta/anilist/info/${anime.id}`
      );
  
      // Find the specific episode
      const episode = animeDetailsResponse.data.episodes.find(
        ep => ep.number === parseInt(episodeNumber)
      );
  
      if (!episode) {
        throw new Error('Episode not found');
      }
  
      return {
        ...episode,
        animeTitle: anime.title.english || anime.title.romaji,
        animeCoverImage: anime.image,
        previousEpisodeId: episodeNumber > 1 
          ? `${animeTitle}-episode-${parseInt(episodeNumber) - 1}` 
          : null,
        nextEpisodeId: episodeNumber < animeDetailsResponse.data.episodes.length 
          ? `${animeTitle}-episode-${parseInt(episodeNumber) + 1}` 
          : null
      };
    } catch (error) {
      console.error("Detailed error fetching episode details:", error.response || error);
      throw error;
    }
  };
  
  export const fetchEpisodeServers = async (episodeId) => {
    try {
      // Decode and clean the episodeId
      const cleanedEpisodeId = decodeURIComponent(episodeId)
        .replace(/[^\w-]/g, '')
        .toLowerCase();
  
      // Split the episodeId
      const parts = cleanedEpisodeId.split('-episode-');
      
      if (parts.length < 2) {
        throw new Error('Invalid episode ID format');
      }
  
      const [animeTitle, episodeNumber] = parts;
  
      // Search for the anime
      const searchResponse = await axios.get(
        `${CONSUMET_BASE_URL}/meta/anilist/${animeTitle}`
      );
  
      if (!searchResponse.data.results || searchResponse.data.results.length === 0) {
        throw new Error('Anime not found');
      }
  
      const anime = searchResponse.data.results[0];
  
      // Fetch full anime details
      const animeDetailsResponse = await axios.get(
        `${CONSUMET_BASE_URL}/meta/anilist/info/${anime.id}`
      );
  
      // Find the specific episode
      const episode = animeDetailsResponse.data.episodes.find(
        ep => ep.number === parseInt(episodeNumber)
      );
  
      if (!episode) {
        throw new Error('Episode not found');
      }
  
      // Fetch servers for this episode
      const serversResponse = await axios.get(
        `${CONSUMET_BASE_URL}/anime/gogoanime/servers/${episode.id}`
      );
  
      return serversResponse.data || [];
    } catch (error) {
      console.error("Detailed error fetching episode servers:", error.response || error);
      return [];
    }
  };
  
  export const searchAnime = async (query) => {
      try {
        const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/${encodeURIComponent(query)}`, {
          params: { q: query },
        });
        // Check and return the results
      return response.data.results || [];
    } catch (error) {
      console.error('Error fetching anime search results:', error);
      return [];
    }
  };

// Optional: Error handling wrapper
export const safeApiCall = async (apiCall, defaultValue = []) => {
  try {
    return await apiCall();
  } catch (error) {
    console.error('API call failed:', error);
    return defaultValue;
  }
};

export const AdvancedSearch = async ({
  query = "",
  type = "ANIME",
  page = 1,
  perPage = 20,
  season = "",
  format = "",
  sort = ["POPULARITY_DESC"],
  genres = [],
  id = "",
  year = "",
  status = ""
}) => {
  try {
    // Validate and clean parameters
    const cleanQuery = query.trim();
    const cleanGenres = genres.filter(genre => genre && genre.trim() !== '');
    const cleanYear = year ? year.toString().trim() : "";

    // Construct query parameters manually
    const params = new URLSearchParams();
    
    if (cleanQuery) params.append('query', cleanQuery);
    params.append('type', type);
    params.append('page', page);
    params.append('perPage', perPage);
    
    if (season) params.append('season', season);
    if (format) params.append('format', format);
    
    // Add sort parameters
    sort.forEach(sortMethod => {
      params.append('sort[]', sortMethod);
    });
    
    // Add genres
    cleanGenres.forEach(genre => {
      params.append('genres[]', genre);
    });
    
    if (id) params.append('id', id);
    if (cleanYear) params.append('year', cleanYear);
    if (status) params.append('status', status);

    // Construct full URL
    const url = `${CONSUMET_BASE_URL}/meta/anilist/advanced-search?${params.toString()}`;

    console.log('Request URL:', url); // Log the full URL for debugging

    const response = await axios.get(url);

    // Additional validation of response
    if (!response.data || !response.data.results) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    console.error('Detailed Advanced Search Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      url: error.config?.url
    });

    // Throw a more informative error
    throw new Error(`Advanced Search Failed: ${error.message}`);
  }
};


/**
 * Advanced Anime Search with Filters
 * @param {Object} filters - Search filters
 * @returns {Promise<Array>} Filtered anime list
 */
// In src/services/Consumet.js
export const advancedAnimeSearch = async (params) => {
  try {
    // Prepare the query parameters according to the API specification
    const query = {
      page: params.page || 1,
      perPage: params.perPage || 20,
      type: 'ANIME', // Always set to ANIME as per requirements
    };

    // Map genres (ensure they match the API's genre list)
    if (params.genres && params.genres.length > 0) {
      query.genres = params.genres;
    }

    // Map status
    if (params.status) {
      const statusMapping = {
        'RELEASING': 'RELEASING',
        'FINISHED': 'FINISHED',
        'NOT_YET_RELEASED': 'NOT_YET_RELEASED',
        'CANCELLED': 'CANCELLED',
        'HIATUS': 'HIATUS'
      };
      query.status = statusMapping[params.status] || params.status;
    }

    // Map type to format
    if (params.type) {
      const formatMapping = {
        'TV': 'TV',
        'MOVIE': 'MOVIE',
        'OVA': 'OVA',
        'ONA': 'ONA',
        'SPECIAL': 'SPECIAL',
        'TV_SHORT': 'TV_SHORT',
        'MUSIC': 'MUSIC'
      };
      query.format = formatMapping[params.type] || params.type;
    }

    // Map year
    if (params.yearFrom || params.yearTo) {
      query.year = `${params.yearFrom || ''}-${params.yearTo || ''}`;
    }

    // Sorting
    const sortMapping = {
      'popularity': 'POPULARITY',
      'rating': 'SCORE_DESC',
      'latest': 'START_DATE_DESC'
    };
    query.sort = [sortMapping[params.sortBy] || 'POPULARITY_DESC'];

    // Add rating filter (if applicable)
    if (params.rating) {
      // Note: The API doesn't have a direct rating filter, 
      // so we might need to handle this client-side
    }

    // Debug log
    console.log('Advanced Anime Search Params:', query);

    // Make the API call
    const response = await axios.get(`${CONSUMET_BASE_URL}/meta/anilist/advanced-search`, {
      params: query
    });

    // Return the results
    return response.data.results || [];
  } catch (error) {
    console.error('Advanced anime search error:', error);
    
    // More detailed error logging
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }

    return [];
  }
}